import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import axios from 'axios'
import router from './router'
import store from './store'
import numeral from 'numeral'
import moment from 'moment'
import LoadScript from 'vue-plugin-load-script';
import vue3LoadingShimmer from "vue3-loading-shimmer"

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

createApp(App).use(store).use(router,axios,numeral,moment,LoadScript,vue3LoadingShimmer).mount('#app')
