import { createStore } from 'vuex'

export default createStore({
  state:  {
      isAuthenticated: false,
      token: '',
      user: {},
      profile: {}
  },
  getters: {
    user (state){
      return state.user;
    }
  },
  mutations: {
    initializeStore(state){
      if(localStorage.getItem('token')){
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      }else{
        state.token = ''
        state.isAuthenticated = false
      }
    },
    setToken(state,token){
      // console.log(data)
      state.token = token
      state.isAuthenticated = true
    },

    removeToken(state){
      state.token =''
      state.isAuthenticated = false
    },


  },
  actions: {
  },
  modules: {
  }
})
