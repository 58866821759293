<template>

  <!-- ===============>> footer start here <<================= -->
  <footer class="footer brand-1">
    <div class="container">
      <div class="footer__wrapper">
        <!-- footerCopy.txt -->
        <div class="footer__bottom">
          <div class="footer__end">
            <div class="footer__end-copyright">
              <p class=" mb-0">© {{ copyRightDate }} All Rights Reserved.</p>

              
            </div>
            <div class="footer__end-copyright">
              <p class="mb-0 end-footer-color"><a href="/terms-and-conditions">Terms & Conditions</a> | <a href="/privacy-policy">Privacy Policy</a></p>
              </div>
           
          </div>
        </div>
      </div>
    </div>
    

     <!-- ===============>> scrollToTop start here <<================= -->
  <a href="#" class="scrollToTop scrollToTop--home1"><i class="fa-solid fa-arrow-up-from-bracket"></i></a>
  <!-- ===============>> scrollToTop ending here <<================= -->

  {{ whatsapp(pages.whatsapp_phone) }}
  </footer>
  <!-- ===============>> footer end here <<================= -->


</template>
<script>
export default {
  props: ["pages"],
    name: "Footer",
    computed: {
    copyRightDate() {
      return new Date().getFullYear();
    },
    websiteName() {
      return axios.defaults.baseURL;
    },
  },
  methods: {
    whatsapp(phoneString) {
      var options = {
        whatsapp: phoneString, // WhatsApp number
        call_to_action: "Message us", // Call to action
        button_color: "#FF6550", // Color of button
        position: "left", // Position may be 'right' or 'left'
      };
      var proto = document.location.protocol,
        host = "getbutton.io",
        url = proto + "//static." + host;
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = url + "/widget-send-button/js/init.js";
      s.onload = function () {
        WhWidgetSendButton.init(host, proto, options);
      };
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    },
    
  },
}
</script>

<style scoped>
.m-logo img {
  width: 168px;
  height: auto;
}
.footer__linklist-item a:hover {
  color: #ffffff;
}

.end-footer-color a {
  color: #ffffff;
}
.footer__end-copyright {
  display: flex;
  justify-content: center;
}
</style>