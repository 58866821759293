<template>
  <div>
    <section class="page-header bg-color-1"></section>
    <!-- ===============>> account start here <<================= -->
    <section class="account padding-top padding-bottom">
      <div class="container">
        <div
          class="account__wrapper"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <div class="row g-4">
            <div class="col-12">
              <div class="account__content account__content--style1">
                <!-- account tittle -->
                <div class="account__header">
                  <h2>Create your account</h2>
                  <p>
                    Hey there! Ready to join the party? We just need a few
                    details from you to get started. Let's do this!
                  </p>
                </div>

                <!-- account form -->
                <form @submit.prevent="submitForm">
                  <div class="row g-4">
                    <div class="col-12">
                      <div>
                        <label for="account-email" class="form-label"
                          >Email</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          v-model="email"
                          id="account-email"
                          placeholder="Enter your email"
                          required
                          :class="{
                            valid: isValidEmail,
                            invalid: !isValidEmail,
                          }"
                        />
                      </div>

                      <div className="text-danger" v-if="!isValidEmail">
                        Invalid Email Address
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-pass">
                        <label for="account-pass" class="form-label"
                          >Password</label
                        >
                        <input v-if="showPassword"
                          type="text"
                          class="form-control"
                          id="account-pass"
                          v-model="password"
                          placeholder="Password"
                          required
                        />
                        <input v-else
                          type="password"
                          class="form-control"
                          id="account-pass"
                          v-model="password"
                          placeholder="Password"
                          required
                        />
                        

                        <button
                          type="button"
                          id="btnToggle"
                          class="form-pass__toggle"
                        >
                        <span @click="toggleShow" class="togglePassword"><i class="fa" :class="{ 'fa-eye-slash' :showPassword, 'fa-eye': !showPassword }"></i></span>
                        </button>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-pass">
                        <label for="account-cpass" class="form-label"
                          >Confirm Password</label
                        >
                        <input v-if="showPassword"
                          type="text"
                          class="form-control"
                          id="account-cpass"
                          v-model="confirm_password"
                          placeholder="Re-type password"
                          required
                        />
                        <input v-else
                          type="password"
                          class="form-control"
                          id="account-cpass"
                          v-model="confirm_password"
                          placeholder="Re-type password"
                          required
                        />

                        <button
                          type="button"
                          id="btnCToggle"
                          class="form-pass__toggle"
                        >
                        <span @click="toggleShow" class="togglePassword"><i class="fa" :class="{ 'fa-eye-slash' :showPassword, 'fa-eye': !showPassword }"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <button v-if="isLoading==false"
                    type="submit"
                    class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4"
                  >
                    Sign Up
                  </button>
                  <button v-else
                    class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4" disabled
                  >
                    Processing...
                  </button>
                </form>

                <div class="account__switch">
                  <p>
                    Don’t have an account yet?
                    <a href="/login">Login</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "RegisterView",
  data() {
    return {
      email: null,
      password: null,
      confirm_password: null,
      isValidEmail: true,
      isLoading: false,
      showPassword: false,
    };
  },
  watch: {
    email(value) {
      this.validateEmail(value);
    },
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show"
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword
    },
    notify_success(text) {
      toast(text, {
        theme: "colored",
        type: "success",
        autoClose: 3000,
      });
    },

    notify_pending(text) {
      toast(text, {
        theme: "colored",
        type: "warning",
        autoClose: 3000,
      });
    },

    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },

    validateEmail(value) {
      const validationRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (value.match(validationRegex)) {
        this.isValidEmail = true;
      } else {
        this.isValidEmail = false;
      }
    },
    submitForm(e) {
      axios.defaults.headers.common["Authorization"] = "";

      const formData = {
        first_name: this.first_name,
        last_name: this.last_name,
        username: this.email,
        email: this.email,
        password: this.password,
        password2: this.confirm_password,
        profile: {
          phone_no: this.phone_number,
          referred_by: this.referral_code,
          hear_us: this.hear_us,
        },
      };

      if (this.email == null) {
        this.notify_failed("Email field is required");
      } else if (this.isValidEmail == false) {
        this.notify_failed("Invalid email address");
      } else if (this.password == null) {
        this.notify_failed("Password field is required");
      } else if (this.confirm_password == null) {
        this.notify_failed("Confirm Password field is required");
      } else {
        this.isLoading = true;
        axios
          .post("/v1/register/", formData)
          .then((response) => {
            this.isLoading = false;
            if (response.status == 200) {
              this.check_otp();
            }
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            if (error.response) {
              for (const property in error.response.data.data) {
                this.notify_failed(
                  `${error.response.data.data[property][property]}`
                );
              }
              // console.log(JSON.stringify(error.response.data.data));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
    check_otp() {
      const formData = {
        email: this.email,
        password: this.password
      }
      axios
        .post("/v1/login/", formData)
        .then((response) => {
          // console.log(response);
          const token = response.data.key;
          this.$store.commit("setToken", token);
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          localStorage.setItem("token", token);

          this.isLoading = false;
          //   localStorage.setItem("refresh_token", refresh_token);
          // this.$router.push("/dashboard");
          location.href = "/otp";
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.breadcrumb-item,
.breadcrumb-item a,
.breadcrumb-item a:active,
.breadcrumb-item:before {
  color: #ffffff;
}
.trk-btn--primary1 {
  background-color: #76a500;
}
.page-header {
  height: 2px;
  padding-block: 0px;
}
</style>