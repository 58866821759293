<template>
  <!-- ===============>> Banner section start here <<================= -->
  <section class="banner banner--style1 bg--cover bg-color-3 bg-color">
    <div class="container">
      <div class="banner__wrapper">
        <div class="row gy-5 gx-4">
          <div class="col-lg-6 col-md-6">
            <div
              class="banner__content"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h1 class="banner__content-heading">Welcome to BillsPilot</h1>
              <p class="banner__content-moto" v-html="pages.about_us"></p>

              <div class="banner__content-btngroup mybtngroup">
                <div class="btn1">
                  <a
                    href="/register"
                    class="trk-btn trk-btn--border trk-btn--primary1 trk-btn--arrow hero-btn"
                    >Get Started
                  </a>

                  <a
                    href="/login"
                    class="trk-btn trk-btn--border trk-btn--primary1 trk-btn--arrow hero-btn"
                    >Login
                  </a>
                </div>
                <div class="btn1">
                  <a
                    href="/contact-us"
                    class="trk-btn trk-btn--border trk-btn--primary1 trk-btn--arrow hero-btn"
                    >Contact us
                  </a>

                  <a
                    href="/pricing"
                    class="trk-btn trk-btn--border trk-btn--primary1 trk-btn--arrow hero-btn"
                    >Pricing
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="banner__thumb">
              <img src="@/assets/frontend/images/bg/8.png" alt="banner-thumb" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ===============>> Banner section end here <<================= -->
</template>

<script>
export default {
  props: ["pages"],
  name: "Hero",
};
</script>
<style scoped>
.bg-color {
  background-color: #310052;
}
.hero-btn {
  background-color: #76a500;
}
.banner__content-heading,
.banner__content-moto {
  color: #ffffff;
}
.mybtngroup {
  display: grid;
  gap: 10px;
  width: 100%;
}
.btn1 {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.trk-btn--primary1 {
  width: 100%;
}
@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .mybtngroup {
    display: grid;
    gap: 10px;
  }
  .btn1 {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .mybtngroup {
    display: grid;
    gap: 10px;
    width: 100%;
  }
  .btn1 {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .mybtngroup {
    display: grid;
    gap: 10px;
    width: 100%;
  }
  .btn1 {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .mybtngroup {
    display: grid;
    gap: 10px;
    width: 100%;
  }
  .btn1 {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
}
</style>