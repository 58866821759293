<template>
  <Shimmer class="row mb-3 page-loader" v-if="pageLoading" />

  <!-- Container Fluid-->
  <div v-else class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path:'/dashboard' }">{{ this.$route.meta.home_title }} </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="network-logo">
              <img v-if="network_image" :src="network_image" alt="Cable Logo" />
            </div>
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-warning" v-if="pending_flag">
              <span>{{ pending_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>

            <form @submit.prevent="submitForm">
              <!-- {% csrf_token %} -->
              <div class="form-group">
                <label>Service Providers</label>
                <select
                  name="service_providers"
                  v-model="service_providers"
                  @change="handleCableChange"
                  class="form-control"
                  required
                >
                  <option value="">Select Providers</option>
                  <!-- {% for package in packages %} -->
                  <option v-for="pack in packages" :value="pack" :key="pack.key">
                    {{ pack.title }}
                  </option>
                  <!-- {% endfor %} -->
                </select>
              </div>
              <div v-if="service_providers.title" class="form-group">
                <label>Smart Card No/IUC</label>
                <input
                  type="text"
                  @keydown="validateInput"
                  name="smart_no"
                  v-model="smart_no"
                  required
                  class="form-control"
                />
                <p class="text-danger">You can not proceed further until you validate your smart card number. Please click the verify button below.</p>
                <br/>
                
                <button v-if="verify==false"
                  type="button"
                  @click="handleSmartNoChange"
                  class="btn btn-primary"
                >
                  Verify
                </button>
                <button v-else
                  type="button"
                  disabled
                  class="btn btn-primary"
                >
                  Verifying... Please wait
                </button>

                <div v-if="customer_show" class="show_det">
                  <p v-if="customer_details.Customer_Name">
                    <strong>Customer Name:</strong>
                    {{ customer_details.Customer_Name }}
                  </p>
                  <p v-if="customer_details.Current_Bouquet">
                    <strong>Current Bouquet:</strong>
                    {{ customer_details.Current_Bouquet }}
                  </p>
                 
                </div>
              </div>
              <div v-if="validated" class="form-group">
                
                <label>Cable Plans</label>
                <select
                  name="cable_plan"
                  v-model="cable_plan"
                  class="form-control"
                  @change="getDiscount"
                  required
                >
                  <option>Select Plan</option>
                  <option
                    v-for="plan in plans"
                    :value="plan"
                    :key="plan.variation_code"
                  >
                    {{ plan.name }}
                  </option>
                </select>
              </div>
              <div v-if="validated" class="form-group">
                <label> Amount</label>
                <input
                  name="amt"
                  type="number"
                  id="amt"
                  readonly
                  v-model="cable_plan.variation_amount"
                  class="form-control"
                />
              </div>
              
              <div class="form-group">
                <label>Phone </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  v-model="phone"
                  @keydown="validateInput"
                  class="form-control"
                />
              </div>
              <div
                v-if="amount_charged_text"
                style="margin-bottom: 10px; color:#76a500;"
              >
                {{ amount_charged_text }}
              </div>
              <div v-if="cable_plan.name || service_providers.title == 'STARTIMES'" class="form-group">
                <button
                  type="submit"
                  v-if="isLoading == false"
                  class="btn btn-primary"
                >
                  Process
                </button>

                <button v-else class="btn btn-primary" disabled>
                  Processing...
                </button>
              </div>
              <div v-else class="form-group">
                <button
                  type="submit"
                  disabled
                  class="btn btn-primary"
                >
                  Process
                </button>

              </div>

              <router-link to="/dashboard/transactions" class="nav-link"
        ><i class="fas fa-fw fa-th"></i> <span>View Transactions</span></router-link
      >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---Container Fluid-->
</template>
    
<script>
import axios from "axios";
import Shimmer from "vue3-loading-shimmer";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = "Bearer " + token;

export default {
  props: ['user','profile'],
  name: "Cable",
  components: {
    Shimmer,
  },
  data() {
    return {
      service_providers: {},
      cable_plan: {},
      amt: "",
      phone: "",
      smart_no: "",
      customer_details: {},
      discount: 0,
      amount_charged_text: "",
      network_image: "",
      errors: [],
      success_flag: "",
      pending_flag: "",
      packages: {},
      plans: {},
      isLoading: false,
      isLoading2: false,
      validated: false,
      customer_show: false,
      pageLoading: false,
      verify:false,
    };
  },
  mounted() {
    this.getPackages();
  },

  methods: {
    validateInput(event){
      if(event.shiftKey && ((event.keyCode >=48 && event.keyCode <=57) 
             || (event.keyCode >=186 &&  event.keyCode <=222))){
        // Ensure that it is a number and stop the Special chars
         event.preventDefault();
     }
     else if ((event.shiftKey || event.ctrlKey) && (event.keyCode > 34 && event.keyCode < 40)){     
          // let it happen, don't do anything
     }      
     else{
        // Allow only backspace , delete, numbers               
        if (event.keyCode == 9 || event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 39 ||event.keyCode == 37 
                || (event.keyCode >=48 && event.keyCode <=57)) {
            // let it happen, don't do anything
        }
        else {
           // Ensure that it is a number and stop the key press
                event.preventDefault();                   
        }
     }
    },
    getPackages() {
      this.pageLoading = true
      axios
        .get("/v1/get-tv-packages/")
        .then((response) => {
          // console.log(response.data.data);
          this.packages = response.data.data;
          this.pageLoading = false
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
              this.errors.push(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          this.pageLoading = false
        });
    },

    validate(e) {
      const subcategory_id = this.service_providers.id;
      const type = this.service_providers.title;
      const smart_no = this.smart_no;

      this.customer_details = {};
      this.success_flag ="";
      this.pending_flag ="";
      this.errors =[];
      this.verify = true;

      if (smart_no == "") {
        this.errors.push("Smart/IUC number required");
        this.notify_failed("Smart/IUC number required");
      } else {
        this.isLoading2 = true;
        this.amt = "";

        this.errors = [];

        axios
          .get(
            "/v1/validate-tv/" +
              subcategory_id +
              "/" +
              type +
              "/" +
              smart_no +
              "/"
          )
          .then((response) => {
            console.log(response)
            // console.log(response.data.data.data.content);
            var my_error = response.data.data.data.content.error;
            this.verify = false;
            if (my_error == undefined) {
              this.customer_details = response.data.data.data.content;
              this.customer_show = true;
            } else {
              this.errors.push(my_error);
            }

            this.isLoading2 = false;
            this.validated = true;
            
            // if (type != "STARTIMES") {
            //   this.validated = true;
            // } else {
            //   this.validated = false;
            // }

            //   this.packages = response.data.data;
          })
          .catch((error) => {
              this.isLoading = false;
              // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
              this.verify = false;
            });
      }
    },

    handleCableChange() {
      this.errors = [];
      this.amt = "";
      this.amount_charged_text = "";
      this.customer_details = "";

      const service_type = this.service_providers.title;
      this.discount = this.service_providers.amount;
      this.network_image =
        axios.defaults.baseURL + this.service_providers.image;

      if (this.service_providers != "") {
        axios
          .get("/v1/get-tv-plan/" + service_type + "/")
          .then((response) => {
            console.log(response)
            // console.log(response.data.data.data.content.varations);
            this.plans = response.data.data.data;
            // discount = response.data.amount;
            //   this.discount = response.data.data[0].amount;
            //   this.network_image =
            //     axios.defaults.baseURL + response.data.data[0].image;
            // console.log(this.discount)
            // this.amount_charged_text = this.getDiscount(this.amt,response.data.data.amount)
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      } else {
        this.network_image = "";
      }
      //   console.log(this.amount_charged_text);
    },

    handleSmartNoChange() {
      this.customer_details = {};
      this.validate();
    },

  
    getDiscount() {
      const myDiscount = this.service_providers.amount;
      var myAmount = this.cable_plan.variation_amount;
      var amount_charged = (myAmount - myAmount * (myDiscount / 100)).toFixed(2);

      this.amount_charged_text =
        "You will be charged ₦" + amount_charged.toString();

      // console.log(this.amount_charged_text);
    },

    notify_success(text) {
      toast(text, {
        theme: "colored",
        type: "success",
        autoClose: 3000,
      });
    },

    notify_pending(text) {
      toast(text, {
        theme: "colored",
        type: "warning",
        autoClose: 3000,
      });
    },

    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },


    submitForm(e) {
      this.errors = [];
      this.amt =
        this.cable_plan.amount != ""
          ? this.cable_plan.amount
          : this.customer_details.Renewal_Amount;

      this.success_flag = "";
      this.pending_flag = "";
    
      // console.log(this.amt);

      if (this.phone == "") {
        this.errors.push("Phone number is required!");
        this.notify_failed("Phone number is required!");
      } else if (this.amt == "") {
        this.errors.push("Amount is required!");
        this.notify_failed("Phone number is required!");
      } else if (this.amt < 100) {
        this.errors.push("Amount can not be less than ₦100");
        this.notify_failed("Amount can not be less than ₦100");
      }else if (this.service_providers == []) {
        this.errors.push("Select a provider from the list");
        this.notify_failed("Select a provider from the list");
      } else {
        const formData = {
          phone: this.phone,
          code:this.cable_plan.variation_code,
          service_type: this.service_providers.title,
          smart_no: this.smart_no,
          bouquet_name: this.cable_plan.name,
          amount:this.cable_plan.variation_amount,
          subscription_type: "renew",
          subcategory_id: this.service_providers.id,
        };
        console.log(formData);
        // "size": size,
        //     "title": plan_name,
        //     "subcategory_id": subcategory_id,

        this.isLoading = true;
        axios
          .post("/v1/purchase-cable/", formData)
          .then((response) => {
            console.log(response);
            if(response.status == 200){
              this.success_flag = response.data.message;
              this.notify_success(response.data.message);
            }else if(response.status == 201){
              this.pending_flag = response.data.message;
              this.notify_pending(response.data.message);
            }
            
            
            this.isLoading = false;
            this.smart_no = "";
            this.customer_details = {};
            this.cable_plan = {};
            this.phone = "";
            this.amount_charged_text = "";
            this.service_providers = {};
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
    
    <style scoped>
.network-logo {
  text-align: center;
  margin-bottom: 20px;
}
.network-logo img {
  width: 100px;
}
.network-error {
  color: red;
}
.notification {
  margin: 10px 0 10px;
}
.card-body {
  padding: 30px;
}
label {
  font-weight: bold;
}
input {
  margin-bottom: 20px;
}
.row {
  margin-bottom: 50px;
}
.show_det {
  background-color: #cdcdcd;
  padding: 10px;
  border-radius: 5px;
  border-color: grey;
  margin: 10px 0 10px;
}
#amt{
  pointer-events: none;
}
a:link {
  text-decoration: none;
}
.btn-primary{
  background-color: #76a500;
  border-color: #76a500;
}

.nav-link {
  color: #310052;
}

.page-loader{
  height: 50%;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
}
</style>