<template>
  <Shimmer class="row mb-3 page-loader" v-if="pageLoading" />

  <!-- Container Fluid-->
  <div v-else class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path:'/dashboard' }">{{ this.$route.meta.home_title }} </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="network-logo">
              <img
                v-if="network_image"
                :src="network_image"
                alt="Exam Pins Logo"
              />
            </div>
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-warning" v-if="pending_flag">
              <span>{{ pending_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>

            <form @submit.prevent="submitForm">
              <!-- {% csrf_token %} -->
              <div class="form-group">
                <label>Exam Type</label>
                <select
                  name="service_providers"
                  v-model="service_providers"
                  @change="handleExamChange"
                  class="form-control"
                  required
                >
                  <option value="">Select Exam </option>
                  <!-- {% for package in packages %} -->
                  <option v-for="pack in packages" :value="pack" :key="pack.key">
                    {{ pack.title }}
                  </option>
                  <!-- {% endfor %} -->
                </select>
              </div>

              <div class="form-group">
                <label>Select Type</label>
                <select
                  name="exam_type"
                  v-model="exam_type"
                  class="form-control"
                  @change="getDiscount"
                  required
                >
                  <option>Select Exam Type</option>
                  <option
                    v-for="plan in plans"
                    :value="plan"
                    :key="plan.variation_code"
                  >
                    {{ plan.name }}
                  </option>
                </select>
              </div>

              <div v-if="service_providers.title=='JAMB'" class="form-group">
                <label>Profile ID</label>
                <input
                  type="text"
                  name="profile_id"
                  v-model="profile_id"
                  @keydown="validateInput"
                  required
                  class="form-control"
                />
                <br/>
                <button v-if="verify==false"
                  type="button"
                  @click="handleProfileID"
                  class="btn btn-primary"
                >
                  Verify
                </button>
                <button v-else
                  type="button"
                  disabled
                  class="btn btn-primary"
                >
                  Verifying... Please wait
                </button>
                

                <div v-if="customer_show" class="show_det">
                  <p v-if="customer_details.Customer_Name">
                    <strong>Customer Name:</strong>
                    {{ customer_details.Customer_Name }}
                  </p>
                </div>
                
              </div>

              <div v-if="exam_type.name != null" class="form-group">
                <label> Amount</label>
                <input
                  name="amt"
                  type="number"
                  id="amt"
                  readonly
                  v-model="exam_type.variation_amount"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label>Phone </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  v-model="phone"
                  @keydown="validateInput"
                  class="form-control"
                />
              </div>
              <div
                v-if="amount_charged_text"
                style="margin-bottom: 10px; color:#76a500;"
              >
                {{ amount_charged_text }}
              </div>
              <div v-if="validated" class="form-group">
                <button
                  type="submit"
                  v-if="isLoading == false"
                  class="btn btn-primary"
                  
                >
                  Process
                </button>

                <button v-else class="btn btn-primary" disabled>
                  Processing...
                </button>
              </div>
              <div v-else class="form-group">
                <button class="btn btn-primary" disabled>Process</button>
              </div>
              <router-link to="/dashboard/transactions" class="nav-link"
        ><i class="fas fa-fw fa-th"></i> <span>View Transactions</span></router-link
      >
              
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Shimmer from "vue3-loading-shimmer";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  props: ['user','profile'],

  name: "Exam",
  components: {
    Shimmer,
  },
  data() {
    return {
      service_providers: {},
      amt: "",
      phone: "",
      exam_type: {},
      profile_id: "",
      discount: 0,
      amount_charged_text: "",
      network_image: "",
      errors: [],
      success_flag: "",
      pending_flag: "",
      packages: {},
      plans: {},
      isLoading: false,
      validated: false,
      verify: false,
      customer_show: false,
      pageLoading: false,
    };
  },
  mounted() {
    this.getPackages();
  },

  methods: {
    validateInput(event){
      if(event.shiftKey && ((event.keyCode >=48 && event.keyCode <=57) 
             || (event.keyCode >=186 &&  event.keyCode <=222))){
        // Ensure that it is a number and stop the Special chars
         event.preventDefault();
     }
     else if ((event.shiftKey || event.ctrlKey) && (event.keyCode > 34 && event.keyCode < 40)){     
          // let it happen, don't do anything
     }      
     else{
        // Allow only backspace , delete, numbers               
        if (event.keyCode == 9 || event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 39 ||event.keyCode == 37 
                || (event.keyCode >=48 && event.keyCode <=57)) {
            // let it happen, don't do anything
        }
        else {
           // Ensure that it is a number and stop the key press
                event.preventDefault();                   
        }
     }
    },
    notify_success(text) {
      toast(text, {
        theme: "colored",
        type: "success",
        autoClose: 3000,
      });
    },

    notify_pending(text) {
      toast(text, {
        theme: "colored",
        type: "warning",
        autoClose: 3000,
      });
    },

    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },
    getPackages() {
      this.pageLoading = true
      this.errors = [];
      axios
        .get("/v1/get-exam-packages/")
        .then((response) => {
          // console.log(response.data.data);
          this.packages = response.data.data;
          this.pageLoading = false
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message)

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          this.pageLoading = false
        });
    },

    handleExamChange() {
        this.errors = [];
        this.exam_type.variation_amount = "";
        this.amount_charged_text = "";
        this.success_flag = "";
  
        const service_type = this.service_providers.title;
        this.discount = this.service_providers.amount;
        this.network_image =
          axios.defaults.baseURL + this.service_providers.image;

          if (this.service_providers != "") {
        axios
          .get("/v1/get-exam-type/" + service_type + "/")
          .then((response) => {
            console.log(response);
            this.plans = response.data.data.data;
            this.validated = true;
        
            // discount = response.data.amount;
            //   this.discount = response.data.data[0].amount;
            //   this.network_image =
            //     axios.defaults.baseURL + response.data.data[0].image;
            // console.log(this.discount)
            // this.amount_charged_text = this.getDiscount(this.amt,response.data.data.amount)
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message)

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      } else {
        this.network_image = "";
      }

          // this.network_image = "";
          
        //   console.log(this.amount_charged_text);
      },

      handleProfileID(){
          this.customer_details = {};
          this.validate();
      },

      validate(e) {
      const subcategory_id = this.service_providers.id;
      const service_id = this.service_providers.title;
      const profile_id = this.profile_id;
      const service_type = this.exam_type.variation_code

      this.customer_details = {};
      this.success_flag ="";
      this.pending_flag ="";
      this.errors =[];
      this.verify = true;

      if (profile_id == "" && service_id == "JAMB") {
        this.errors.push("Profile ID is required");
      } else {
        this.isLoading2 = true;
        this.amt = "";

        this.errors = [];

        axios
          .get(
            "/v1/validate-exam/" +
              subcategory_id +
              "/" +
              service_id +
              "/" +
              profile_id +
              "/"+
              service_type +
              "/"
          )
          .then((response) => {
            this.verify = false;
            // console.log(response.data.data.data.content);
            var my_error = response.data.data.content.error;
            if (my_error == undefined) {
              this.customer_details = response.data.data.content;
              this.customer_show = true;
              this.validated = true;
            } else {
              this.errors.push(my_error);
              this.validated = false;
            }

            this.isLoading2 = false;

            
             
            //   this.packages = response.data.data;
          })
          .catch((error) => {
              this.isLoading = false;
              // console.log(error.response);
              if (error.response) {
              this.errors.push(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.verify = false;
            });
      }
    },
    getDiscount() {
        const myDiscount = this.service_providers.amount;
       
        var amount_charged = (this.exam_type.variation_amount - (this.exam_type.variation_amount * (myDiscount / 100))).toFixed(2);
  
        this.amount_charged_text =
          "You will be charged ₦" + amount_charged.toString();
  
        // console.log(this.amount_charged_text);
      },

      submitForm(e) {
        this.errors = [];
        this.success_flag ="";
        this.pending_flag ="";
  
        if (this.phone == "") {
          this.errors.push("Phone number is required!");
          this.notify_failed("Phone number is required!");
        } else if (this.exam_type.variation_amount == "") {
          this.errors.push("Amount is required!");
          this.notify_failed("Amount is required!");
        }else if (this.service_providers == []) {
        this.errors.push("Select a provider from the list");
        this.notify_failed("Select a provider from the list");
      } else {
          const service_id =  this.service_providers.title;
        // console.log(service_idx)

          const formData = {
            subcategory_id: this.service_providers.id,
            phone: this.phone,
            billersCode: this.profile_id,
            variation_code: this.exam_type.variation_code,
            service_id: service_id,
            amount: this.exam_type.variation_amount,
          };
          // console.log(formData);
          // "size": size,
          //     "title": plan_name,
          //     "subcategory_id": subcategory_id,
  
          this.isLoading = true;
          axios
            .post("/v1/purchase-exam-pins/", formData)
            .then((response) => {
              // console.log(response);
              if(response.status == 200){
                this.success_flag = response.data.message + ". Please check your transaction history for your Pin Details.";
                this.notify_success(response.data.message + ". Please check your transaction history for your Pin Details.");
            }else if(response.status == 201){
              this.pending_flag =
              response.data.message;
              this.notify_pending(response.data.message);
            }

              
              this.isLoading = false;
              this.meter_no = "";
              this.meter_type = "";
              this.customer_details= {};
              this.amt = "";
              this.phone = "";
              this.amount_charged_text="";
              this.service_providers = {};
            })
            .catch((error) => {
              this.isLoading = false;
              // console.log(error.response);
              if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            });
        }
      },



  },
};
</script>
<style scoped>
.network-logo {
  text-align: center;
  margin-bottom: 20px;
}
.network-logo img {
  width: 100px;
}
.network-error {
  color: red;
}
.notification {
  margin: 10px 0 10px;
}
.card-body {
  padding: 30px;
}
label {
  font-weight: bold;
}
input {
  margin-bottom: 20px;
}
.row {
  margin-bottom: 50px;
}
.show_det {
  background-color: #cdcdcd;
  padding: 10px;
  border-radius: 5px;
  border-color: grey;
  margin: 10px 0 10px;
}
#amt{
  pointer-events: none;
}
a:link {
  text-decoration: none;
}
.btn-primary{
  background-color: #76a500;
  border-color: #76a500;
}

.nav-link {
  color: #310052;
}
.page-loader{
  height: 50%;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
}
</style>