<template>
  <div>
  
    <!-- ===============>> account start here <<================= -->
    <section class="account padding-top padding-bottom">
      <div class="container">
        <div
          class="account__wrapper"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <div class="row g-4">
            <div class="col-lg-12">
              <div class="account__content account__content--style1">
                <!-- account tittle -->
                <div class="account__header">
                  <h2>Fill in your personal information</h2>
                </div>

                <!-- account form -->
                <form @submit.prevent="submitForm">
                  <div class="row g-4">
                    <div class="col-12 col-md-6">
                      <div>
                        <label for="first-name" class="form-label"
                          >First name</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          id="first-name"
                          required
                          v-model="first_name"
                          placeholder="Ex. Jhon"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div>
                        <label for="last-name" class="form-label"
                          >Last name</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          id="last-name"
                          required
                          v-model="last_name"
                          placeholder="Ex. Doe"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-12">
                      <div>
                        <label for="last-name" class="form-label"
                          >Phone Number</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          id="last-name"
                          v-model="phone"
                          placeholder="Ex. 08011111111"
                          :class="{
                            valid: isValidPhoneNumber,
                            invalid: !isValidPhoneNumber,
                          }"
                          required
                          @keyup="validatePhoneNumber"
                        />
                        <div className="text-danger" v-if="!isValidPhoneNumber">
                            Invalid Phone Number
                        </div>
                      </div>
                    </div>
                  </div>

                  <button v-if="isLoading==false"
                    type="submit"
                    class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4"
                  >
                    Done
                  </button>
                  <button v-else
                    class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4" disabled
                  >
                    Processing...
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ===============>> account end here <<================= -->
  </div>
</template>
  
  <script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = "Bearer " + token;

export default {
  name: "PersonalInfoView",
  data() {
    return {
      first_name: "",
      last_name: "",
      phone: "",
      isValidPhoneNumber: true,
      isLoading: false,
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      console.log("otp");
    },
    clickEvent(last) {
      console.log(event.target.value);
      if (event.target.value.length) {
        document.getElementById(last).focus();
      }
    },
    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },
    validatePhoneNumber() {
      const validationRegex = /^\d{11}$/;
      if (this.phone.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    submitForm(e) {
      const formData = {
        first_name: this.first_name,
        last_name: this.last_name,
        profile: {
          phone_no: this.phone,
        },
      };

      console.log(formData)

      if (this.first_name == "") {
        this.notify_failed("First Name is required");
      } else if (this.last_name == "") {
        this.notify_failed("Last Name is required");
      } else if (this.phone == "") {
        this.notify_failed("Phone number is required");
      } else if (this.phone.length < 11) {
        this.notify_failed("Phone number is invalid");
      } else if (this.isValidPhoneNumber == false) {
        this.notify_failed(
          "Invalid phone number, the format allowed e.g 07012345678, 08012345678 and 11 digits only"
        );
      } else {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios
          .put("/v1/user/update-profile/", formData)
          .then((response) => {
            console.log(response);
            if (response.status == 200) {
              this.isLoading = false;
              //   localStorage.setItem("refresh_token", refresh_token);
              location.href="/login"
              // this.$router.push("/dashboard");
              // location.href = "/dashboard";
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.notify_failed(error.response.data.message);
          });
      }
    },
  },
};
</script>
  <style scoped>
.breadcrumb-item,
.breadcrumb-item a,
.breadcrumb-item a:active,
.breadcrumb-item:before {
  color: #ffffff;
}
.trk-btn--primary1 {
  background-color: #76a500;
}

.account__content--style1 {
  background-color: #ebeaf3;
}
</style>