<template>
  <Shimmer class="row mb-3 page-loader" v-if="pageLoading" />

  <!-- Container Fluid-->
  <div v-else class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body table-responsive">
            <p><b>Profile Info</b></p>
            <hr />
            <table class="table table-bordered" style="overflow-x: auto">
              <tr>
                <td>Name</td>
                <td>{{ user_detail.first_name }} {{ user_detail.last_name }}</td>
              </tr>
              <tr>
                <td>Wallet Balance</td>
                <td>&#8358;{{ formatNumber(profile_detail.wallet_balance) }}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{{ profile_detail.phone_no }}</td>
              </tr>
              <tr>
                <td>Date Joined</td>
                <td>{{ formatDate(user_detail.date_joined) }}</td>
              </tr>
              <!-- <tr>
                <td>Referral Link</td>
                <td>{{ profile_detail.referral_link }}</td>
              </tr> -->
              <!-- <tr>
                <td>Bvn verification</td>
                <td v-if="profile_detail.bvn != null" class="text-success">Bvn Verified!</td>
                <td v-else><router-link to="/dashboard/identity-verification" class="text-success"
                  >Click Here</router-link> to verify your bvn.
                </td>
              </tr>
              <tr>
                <td>Nin verification</td>
                <td v-if="profile_detail.nin != null" class="text-success">Nin Verified!</td>
                <td v-else><router-link to="/dashboard/identity-verification" class="text-success"
                  >Click Here</router-link> to verify your nin.
                </td>
              </tr> -->
            </table>
          </div>
        </div>
      </div>
      
    </div>

    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="card mb-4">
          <div class="card-body">
            <form @submit.prevent="updateWebhook">
        <div class="form-group">
          <label>Webhook Url</label>
          <input
            type="url"
            class="form-control"
            name="webhook_url"
            v-model="profile_detail.webhook_url"
          />
        </div>
        <div class="form-group">
          <button
            v-if="isLoading2 == false"
            type="submit"
            class="btn btn-primary"
          >
            Save
          </button>
          <button v-else type="button" class="btn btn-primary">
            Processing...
          </button>
        </div>
        </form>
          </div>
          
        </div>
        
      </div>
      
    </div> -->
    <div class="row m">
      <div class="col-lg-6">
        <!-- Form Basic -->
        <div class="card mb-6">
          <div class="card-body">
            <p><b>Change your Password</b></p>
            <hr />
            <div
              class="notification alert alert-success"
              v-if="password_success_flag"
            >
              <span>{{ password_success_flag }}</span>
            </div>
            <div
              class="notification alert alert-danger"
              v-if="password_errors.length"
            >
              <span
                v-for="password_error in password_errors"
                v-bind:key="password_error"
                >{{ password_error }}</span
              >
            </div>
            <form @submit.prevent="submitPassword">
              <div class="form-group">
                <label>Old Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  v-model="old_password"
                />
              </div>
              <div class="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  v-model="new_password"
                />
              </div>
              <div class="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  v-model="confirm_password"
                />
              </div>
              <div class="form-group">
                <button
                  v-if="isLoading2 == false"
                  type="submit"
                  class="btn btn-primary"
                >
                  Save
                </button>
                <button v-else type="button" class="btn btn-primary">
                  Processing...
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-6">
          <div class="card-body">
            <p><b>Update your bank Info</b></p>
            <hr />
            <div
              class="notification alert alert-success"
              v-if="bank_success_flag"
            >
              <span>{{ bank_success_flag }}</span>
            </div>
            <div
              class="notification alert alert-danger"
              v-if="bank_errors.length"
            >
              <span v-for="bank_error in bank_errors" v-bind:key="bank_error">{{
                bank_error
              }}</span>
            </div>
            <form @submit.prevent="submitBank">
              <div class="form-group">
                <label>Account Name</label>
                <input
                  type="text"
                  v-model="profile_detail.account_name"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>Account Number</label>
                <input
                  type="text"
                  v-model="profile_detail.account_number"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>Bank Name</label>
                <input
                  type="text"
                  v-model="profile_detail.bank_name"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <button
                  v-if="isLoading == false"
                  type="submit"
                  class="btn btn-primary"
                >
                  Save
                </button>
                <button v-else type="button" class="btn btn-primary">
                  Processing...
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import Shimmer from "vue3-loading-shimmer";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = "Bearer " + token;

export default {
  props: ['user','profile'],
  name: "Profile",
  components: {
    Shimmer,
  },
  data() {
    return {
      user_detail: {},
      profile_detail: {},
      picked: "",
      bvn: "",
      bvn_name: "",
      bvn_errors: [],
      bvn_success_flag: "",
      nin: "",
      nin_errors: [],
      nin_success_flag: "",
      old_password: "",
      new_password: "",
      confirm_password: "",
      bank_errors: [],
      bank_success_flag: "",
      password_errors: [],
      password_success_flag: "",
      isLoading: false,
      isLoading2: false,
      pageLoading: false,
    };
  },

  mounted() {
    this.getUser();
  },
 
  methods: {
    getUser() {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      this.pageLoading = true;
      axios
        .get("/v1/user/profile/")
        .then((response) => {
            // console.log(response.data.data);
          this.user_detail = response.data.data;

          this.profile_detail = response.data.data.profile;

          this.pageLoading = false;

          // if(this.profile.bvn == null && this.profile.nin == null){
          //   $(document).ready(function(){
          //   $('#infoModal').modal('show');
          //  })
          // }
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            if(error.response.data.errors[0].code == "authentication_failed"){
              location.href="/login"
            }
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
          this.pageLoading = false
        });
    },
    validateInput(event){
      if(event.shiftKey && ((event.keyCode >=48 && event.keyCode <=57) 
             || (event.keyCode >=186 &&  event.keyCode <=222))){
        // Ensure that it is a number and stop the Special chars
         event.preventDefault();
     }
     else if ((event.shiftKey || event.ctrlKey) && (event.keyCode > 34 && event.keyCode < 40)){     
          // let it happen, don't do anything
     }      
     else{
        // Allow only backspace , delete, numbers               
        if (event.keyCode == 9 || event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 39 ||event.keyCode == 37 
                || (event.keyCode >=48 && event.keyCode <=57)) {
            // let it happen, don't do anything
        }
        else {
           // Ensure that it is a number and stop the key press
                event.preventDefault();                   
        }
     }
    },
    
    formatDate(dateString) {
      const date = new Date(dateString);
      return moment(date).format("Do MMM, YYYY");
      // Then specify how you want your dates to be formatted
      // return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
    },
    formatNumber(numberString) {
      return numeral(numberString).format("0,0.00");
    },
    notify_success(text) {
      toast(text, {
        theme: "colored",
        type: "success",
        autoClose: 3000,
      });
    },

    notify_pending(text) {
      toast(text, {
        theme: "colored",
        type: "warning",
        autoClose: 3000,
      });
    },

    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },
    updateWebhook(e) {
      const webhook_url = this.profile_detail.webhook_url;
      if (webhook_url == "") {
        this.notify_failed("Webhook Url is required!");
      }else {
        const formData = {
          profile: {
            webhook_url: webhook_url,
          }
        };
        // console.log(formData);
        this.isLoading = true;

        axios
          .put("/v1/user/update-profile/", formData)
          .then((response) => {
            console.log(response);
            this.notify_success(response.data.message);
            
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.notify_failed(error.response.data.message);
              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
    submitBank(e) {
      this.bank_errors = [];
      this.bank_success_flag = "";

      const account_name = this.profile_detail.account_name;
      const account_number = this.profile_detail.account_number;
      const bank_name = this.profile_detail.bank_name;

      if (account_name == "") {
        this.bank_errors.push("Account Name is required!");
        this.notify_failed("Account Name is required!");
      } else if (account_number == "") {
        this.bank_errors.push("Account Number is required!");
        this.notify_failed("Account Number is required!");
      } else if (bank_name == "") {
        this.bank_errors.push("Bank Name is required!");
        this.notify_failed("Bank Name is required!");
      } else {
        const formData = {
          account_name: account_name,
          account_number: account_number,
          bank_name: bank_name,
        };
        // console.log(formData);
        this.isLoading = true;

        axios
          .put("/v1/update-bank-details/", formData)
          .then((response) => {
            console.log(response);
            this.bank_success_flag = response.data.message;
            this.notify_success(response.data.message);
            
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.bank_errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message);
              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
    submitPassword(e) {
      this.password_errors = [];
      this.password_success_flag = "";
      this.password_error_flag = "";

      const old_password = this.old_password;
      const new_password = this.new_password;
      const confirm_password = this.confirm_password;

      if (old_password == "") {
        this.password_errors.push("Old password is required!");
        this.notify_failed("Old password is required!");
      } else if (new_password == "") {
        this.password_errors.push("New password is required!");
        this.notify_failed("New password is required!");
      } else if (confirm_password == "") {
        this.password_errors.push("Confirm password is required!");
        this.notify_failed("Confirm password is required!");
      } else if (confirm_password != new_password) {
        this.password_errors.push("Password does not match");
        this.notify_failed("Password does not match");
      } else {
        const formData = {
          old_password: old_password,
          password: new_password,
          password2: confirm_password,
        };
        // console.log(formData);
        this.isLoading2 = true;

        axios
          .put("/v1/change-password/", formData)
          .then((response) => {
            // console.log(response);
            
            this.password_success_flag = response.data.message;
            this.notify_success(response.data.message);
            
            this.isLoading2 = false;
          })
          .catch((error) => {
            this.isLoading2 = false;
            console.log(error.response.data.message);
            if (error.response) {
              this.password_errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
    submitBvn() {
      this.bvn_errors = [];
      this.bvn_success_flag = "";
      const new_bvn_dob = "3-Jun-1995";
      if (this.bvn == "") {
        this.bvn_errors.push("BVN Number is required");
        this.notify_failed("BVN Number is required");
      }else if (this.bvn.length < 11 || this.bvn.length > 11 ) {
        this.bvn_errors.push("BVN Number must be exactly 11 digits");
        this.notify_failed("BVN Number must be exactly 11 digits");
      } else if (this.bvn_name == "") {
        this.bvn_errors.push("Name is required");
        this.notify_failed("Name is required");
      } else {
        this.isLoading = true;
        const formData = {
          bvn: this.bvn,
          name: this.bvn_name,
          dob: new_bvn_dob,
          phone: this.profile.phone_no,
        };

        axios
          .post("/v1/update-bvn/", formData)
          .then((response) => {
            console.log(response);
            // this.close();
            //  this.users.push(this.user);
            this.bvn_success_flag = response.data.message;
            this.notify_success(response.data.message);
            this.isLoading = false;
            //this.$router.push({ name: "users" });
            // window.location.reload();
          })
          .catch((error) => {
            // console.log(error);
            this.bvn_errors = error.response.data.message;
            this.notify_failed(error.response.data.message);
            this.isLoading = false;
          });

        // console.log(formData);
      }
    },
    submitNin() {
      this.nin_errors = [];
      console.log(this.nin)
      if (this.nin == "") {
        this.nin_errors.push("NIN Number is required");
        this.notify_failed("NIN Number is required");
      } else if (this.nin.length < 11 || this.nin.length > 11 ) {
        this.nin_errors.push("NIN Number must be exactly 11 digits");
        this.notify_failed("NIN Number must be exactly 11 digits");
      } else {
        this.isLoading = true;
        const formData = {
          nin: this.nin,
        };

        axios
          .post("/v1/update-nin/", formData)
          .then((response) => {
            console.log(response);
            // this.close();
            //  this.users.push(this.user);
            this.nin_success_flag = response.data.message;
            this.notify_success(response.data.message);
            this.isLoading = false;
            //this.$router.push({ name: "users" });
            // window.location.reload();
          })
          .catch((error) => {
            console.log(error);
            this.nin_errors = error.response.data.message;
            this.notify_failed(error.response.data.message);
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
<style scoped>
.m {
  margin-bottom: 50px;
}
label {
  font-weight: bold;
}
a:link {
  text-decoration: none;
}
.btn-primary {
  background-color: #76a500;
  border-color: #76a500;
}
.bvn_style,
.nin_style {
  padding: 0 20px 20px;
}
.fmi {
  margin-bottom: 20px;
}
.page-loader{
  height: 50%;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
}
</style>