<template>
    <div>
       <Header />
       <router-view :pages="pages"></router-view>
      <Footer :pages="pages" />
    </div>
 
</template>

<script>
import Footer from '@/components/frontend/layouts/Footer.vue';
import Header from '@/components/frontend/layouts/Header.vue';

import axios from 'axios';
export default {
    name: "FrontendLayout",
    components: {
        Header,
        Footer
    },
    data (){
    return {
        pages: {},
    }
  },
    created: function () {
    axios.defaults.headers.common["Authorization"] = "";
    axios.defaults.headers.common["Content-type"] = "application/json";
    axios
      .get("/v1/get-pages/")
      .then((response) => {
        // console.log(response.data.data.about);
        const about_us = response.data.data.about;
        const contact = response.data.data.contact;
        const contact_data = contact.split("|");
        const phone = contact_data[1];
        const phone_link = "tel:" + contact_data[1];

        const social = response.data.data.social;

        const social_data = social.split("|");

        const address = contact_data[0];
        const email = contact_data[2];

        const whatsapp_phone = response.data.data.whatsapp_phone;
        const whatsapp_link = "https://wa.me/"+whatsapp_phone;

        const email_link = "mailto:" + contact_data[2];

        const facebook = "https://facebook.com/" + social_data[0];
        const instagram = "https://instagram.com/" + social_data[1];
        const twitter = "https://twitter.com/" + social_data[2];

        const faq = response.data.data.faq;
        const privacy = response.data.data.privacy;
        const terms = response.data.data.terms;

        this.pages = {
          "about_us": about_us,
          "phone": phone,
          "phone_link": phone_link,
          "whatsapp_phone": whatsapp_phone,
          "whatsapp_link": whatsapp_link,
          "address": address,
          "email": email,
          "email_link": email_link,
          "facebook": facebook,
          "instagram": instagram,
          "twitter": twitter,
          "faq": faq,
          "privacy": privacy,
          "terms": terms,
        };

        

      })
      .catch((error) => {
        if (error.response) {
          console.log(JSON.stringify(error.response.data));
        } else if (error.message) {
          console.log(JSON.stringify(error.message));
        } else {
          console.log(JSON.stringify(error));
        }
      });
  },
    mounted() {
    
    var submenuList = document.querySelectorAll("ul>li>.submenu");
    submenuList.forEach(function (submenu) {
      var parentLi = submenu.parentElement;
      if (parentLi) {
        parentLi.classList.add("menu-item-has-children");
      }
    });

    // Fix dropdown menu overflow problem
    var menuList = document.querySelectorAll("ul");
    menuList.forEach(function (menu) {
      var parentLi = menu.parentElement;
      if (parentLi) {
        parentLi.addEventListener("mouseover", function () {
          var menuPos = menu.getBoundingClientRect();
          if (menuPos.left + menu.offsetWidth > window.innerWidth) {
            menu.style.left = -menu.offsetWidth + "px";
          }
        });
      }
    });

    // Toggle menu on click
    var menuLinks = document.querySelectorAll(".menu li a");

    menuLinks.forEach(function (link) {
      link.addEventListener("click", function (e) {
        e.stopPropagation(); // prevent the event from bubbling up to parent elements
        var element = link.parentElement;
        if (parseInt(window.innerWidth, 10) < 1200) {
          if (element.classList.contains("open")) {
            element.classList.remove("open");
            element.querySelector("ul").style.display = "none";
          } else {
            element.classList.add("open");
            element.querySelector("ul").style.display = "block";
          }
        }
      });
    });

    // Toggle header bar on click
    var headerBar = document.querySelector(".header-bar");
    headerBar.addEventListener("click", function () {
      headerBar.classList.toggle("active");
      var menu = document.querySelector(".menu");
      if (menu) {
        menu.classList.toggle("active");
      }
    });

    //Header
    var fixedTop = document.querySelector("header");
    window.addEventListener("scroll", function () {
      if (window.scrollY > 300) {
        fixedTop.classList.add("header-fixed", "fadeInUp");
      } else {
        fixedTop.classList.remove("header-fixed", "fadeInUp");
      }
    });

    AOS.init();

    const Partner = new Swiper(".partner__slider", {
      spaceBetween: 24,
      grabCursor: true,
      loop: true,
      slidesPerView: 2,
      breakpoints: {
        576: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 4,
        },
        992: {
          slidesPerView: 5,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 6,
          spaceBetween: 25,
        },
      },
      autoplay: {
        delay: 1,
        disableOnInteraction: true,
      },
      speed: 2000,
    });

    // blog  slider here
    const blog = new Swiper(".blog__slider", {
      spaceBetween: 24,
      grabCursor: true,
      loop: true,
      slidesPerView: 1,
      breakpoints: {
        576: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 25,
        },
      },

      autoplay: true,
      speed: 500,
      navigation: {
        nextEl: ".blog__slider-next",
        prevEl: ".blog__slider-prev",
      },
    });

    // testimonial slider

    const testimonial = new Swiper(".testimonial__slider", {
      spaceBetween: 24,
      grabCursor: true,
      loop: true,
      slidesPerView: 1,
      breakpoints: {
        576: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 25,
        },
      },

      autoplay: true,
      speed: 500,

      navigation: {
        nextEl: ".testimonial__slider-next",
        prevEl: ".testimonial__slider-prev",
      },
    });
    // testimonial slider 2
    const testimonial2 = new Swiper(".testimonial__slider2", {
      spaceBetween: 24,
      grabCursor: true,
      loop: true,
      slidesPerView: 1,
      breakpoints: {
        576: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 25,
        },
      },

      autoplay: true,
      speed: 500,

      navigation: {
        nextEl: ".testimonial__slider-next",
        prevEl: ".testimonial__slider-prev",
      },
    });

    // =================== custom trk slider end here =================== //

    // =================== scroll js start here =================== //

    // Show/hide button on scroll
    window.addEventListener("scroll", function () {
      var scrollToTop = document.querySelector(".scrollToTop");

      if (scrollToTop) {
        if (window.pageYOffset > 300) {
          scrollToTop.style.bottom = "7%";
          scrollToTop.style.opacity = "1";
          scrollToTop.style.transition = "all .5s ease";
        } else {
          scrollToTop.style.bottom = "-30%";
          scrollToTop.style.opacity = "0";
          scrollToTop.style.transition = "all .5s ease";
        }
      }
    });

    var scrollToTop = document.querySelector(".scrollToTop");

    if (scrollToTop) {
      // Click event to scroll to top
      scrollToTop.addEventListener("click", function (e) {
        e.preventDefault();
        var scrollDuration = 100; // Set scroll duration in milliseconds
        var scrollStep = -window.scrollY / (scrollDuration / 15);
        var scrollInterval = setInterval(function () {
          if (window.scrollY !== 0) {
            window.scrollBy(0, scrollStep);
          } else {
            clearInterval(scrollInterval);
          }
        }, 15);
      });
    }

    // =================== scroll js end here =================== //

    // =================== count start here =================== //
    new PureCounter();
    // =================== count end here =================== //
  },
}
</script>

<style scoped>
.col-md-5 {
  position:inherit;
      width: 100%;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
}
</style>