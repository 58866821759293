<template>
  <div>
    <!-- ================> Page header start here <================== -->
    <section class="page-header bg-color-1"></section>
    <!-- ================> Page header end here <================== -->

    <!-- ===============>> account start here <<================= -->
    <section class="account padding-top padding-bottom">
      <div class="container">
        <div
          class="account__wrapper"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <div class="row g-4">
            <div class="col-lg-12">
              <div class="account__content account__content--style1">
                <!-- account tittle -->
                <div class="account__header">
                  <h2>Otp Verification</h2>
                  <p>
                    An email with OTP code has been sent to your email address.
                    Please enter otp verification code below
                  </p>
                </div>

                <!-- account form -->
                <form @submit.prevent="submitForm">
                  <div class="row g-4">
                    <div class="otp-container">
                      <div class="userInput">
                        <input
                          type="text"
                          id="ist"
                          maxlength="1"
                          v-model="ist"
                          @keyup="clickEvent('sec')"
                        />
                        <input
                          type="text"
                          id="sec"
                          v-model="sec"
                          maxlength="1"
                          @keyup="clickEvent('third')"
                        />
                        <input
                          type="text"
                          id="third"
                          v-model="third"
                          maxlength="1"
                          @keyup="clickEvent('fourth')"
                        />
                        <input
                          type="text"
                          id="fourth"
                          v-model="fourth"
                          maxlength="1"
                        />
                      </div>
                    </div>
                  </div>

                  <p></p>

                  <p>
                    Didn't receive the code?
                    <a href="#" @click="resendOtp()">Resend</a>
                  </p>

                  <button
                    v-if="isLoading == false"
                    type="submit"
                    class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4"
                  >
                    Next
                  </button>
                  <button
                    v-else
                    class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4"
                    disabled
                  >
                    Processing...
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ===============>> account end here <<================= -->
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = "Bearer " + token;

export default {
  name: "OtpView",
  data() {
    return {
      otp: "",
      ist: "",
      sec: "",
      third: "",
      fourth: "",
      isLoading: false,
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      console.log("otp");
    },
    clickEvent(last) {
      console.log(event.target.value);
      if (event.target.value.length) {
        document.getElementById(last).focus();
      }
    },
    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },
    notify_success(text) {
      toast(text, {
        theme: "colored",
        type: "success",
        autoClose: 3000,
      });
    },
    submitForm(e) {
      const formData = {
        otp: this.ist + this.sec + this.third + this.fourth,
      };

      console.log(formData);

      if (
        this.ist == "" ||
        this.sec == "" ||
        this.third == "" ||
        this.fourth == ""
      ) {
        this.notify_failed(
          "Otp can not be empty. Please enter the otp sent to your email."
        );
      } else {
        const token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        this.isLoading = true;
        axios
          .post("/v1/user/otp-verification/", formData)
          .then((response) => {
            console.log(response);
            if (response.status == 200) {
              this.isLoading = false;
              //   localStorage.setItem("refresh_token", refresh_token);
              location.href = "/personal-info";
              // this.$router.push("/dashboard");
              // location.href = "/dashboard";
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.notify_failed(error.response.data.message);
          });
      }
    },
    resendOtp() {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .get("/v1/user/resend-otp/")
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.isLoading = false;
            this.notify_success("Otp resent to your email box.");
          }
        })
        .catch((error) => {
          console.log(error);
          this.notify_failed(error.response.data.message);
        });
    },
  },
};
</script>
<style scoped>
.breadcrumb-item,
.breadcrumb-item a,
.breadcrumb-item a:active,
.breadcrumb-item:before {
  color: #ffffff;
}
.trk-btn--primary1 {
  background-color: #76a500;
}

.otp-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  align-items: space-around;
  justify-content: center;
}
.userInput {
  display: flex;
  justify-content: center;
  align-items: center;
}
input {
  display: flex;
  padding: 20px !important;
  justify-content: center;
  margin: 10px;
  width: 65px;
  border: 1px solid #76a500;
  border-radius: 5px;
  text-align: center;
  font-family: arimo;
  font-size: 1.2rem;
  background: #eef2f3;
}

.account__content--style1 {
  background-color: #ebeaf3;
}

.page-header {
  height: 2px;
  padding-block: 0px;
}
</style>