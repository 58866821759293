<template>
  
  <!-- ===============>> Pricing section start here <<================= -->
  <section class="pricing padding-top padding-bottom bg-color-2" id="pricing">
    <div class="section-header section-header--max50">
      <h2 class="mb-15 mt-minus-10"><span>pricings </span>We offer</h2>
      <p>We offer the best prices around - from airtime to data, bills, and more!</p>
    </div>
    <div class="container">
      <div class="pricing__wrapper">
        <div class="row g-4 align-items-center">
            
          <div class="col-md-6 col-lg-4" v-for="list in lists"
          :key="list.key">

            <div class="pricing__item">
              <div class="pricing__item-inner">
                <div class="pricing__item-content">

                  <!-- pricing top part -->
                  <div class="pricing__item-top">
                    <h6 class="mb-15"  v-if="list.category_id == 1"> <img
                        width="40px"
                        height="40px"
                        class="title_image"
                        :src="makeImage(list.image)"
                      /> {{ list.title }} Data </h6>
                    <h6 class="mb-15"  v-else-if="list.category_id == 2"> <img
                        width="40px"
                        height="40px"
                        class="title_image"
                        :src="makeImage(list.image)"
                      /> {{ list.title }} Airtime </h6>
                    <h6 class="mb-15"  v-else-if="list.category_id == 3"> <img
                        width="40px"
                        height="40px"
                        class="title_image"
                        :src="makeImage(list.image)"
                      /> {{ list.title }}</h6>
                    <h6 class="mb-15"  v-else-if="list.category_id == 4"> <img
                        width="40px"
                        height="40px"
                        class="title_image"
                        :src="makeImage(list.image)"
                      /> {{ list.title }}</h6>
                    <h6 class="mb-15"  v-else-if="list.category_id == 6"> <img
                        width="40px"
                        height="40px"
                        class="title_image"
                        :src="makeImage(list.image)"
                      /> {{ list.title }}</h6>
                    <h6 class="mb-15"  v-else-if="list.category_id == 11"> <img
                        width="40px"
                        height="40px"
                        class="title_image"
                        :src="makeImage(list.image)"
                      /> {{ list.title }}</h6>
                  </div>

                  <!-- pricing middle part -->
                  <div class="pricing__item-middle">
                    <ul class="pricing__list">

                      <li class="pricing__list-item" v-if="
                      list.category_id == 2 ||
                      list.category_id == 3 ||
                      list.category_id == 4 ||
                      list.category_id == 6 ||
                      list.category_id == 11
                    "><span><img src="@/assets/frontend/images/icon/check.svg" alt="check"
                            class="dark"></span>
                            Discount of {{ list.products.amount1 }}%</li>

                      <li class="pricing__list-item" v-else-if="
                      list.id == 55 ||
                      list.id == 56 ||
                      list.id == 57 ||
                      list.id == 58 ||
                      list.id == 59
                    "><span><img src="@/assets/frontend/images/icon/check.svg" alt="check"
                            class="dark"></span>
                            Discount of {{ list.products.amount1 }}%</li>
                            
                      <li class="pricing__list-item" v-else
                      v-for="product in list.products[0].data"
                      :key="product.plan">
                      <span><img src="@/assets/frontend/images/icon/check.svg" alt="check"
                            class="dark"></span>
                            {{ product.plan}} - &#8358;{{ formatNumber(product.amount1) }}</li>
                  
                    </ul>

                  </div>

                  <!-- pricing bottom part -->
                  <div class="pricing__item-bottom">
                    <a href="/login" class="trk-btn trk-btn--outline home-btn-outline">Purchase Plan</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </section>
  <!-- ===============>> Pricing section ends here <<================= -->
</template>

<script>
import axios from "axios";
import numeral from "numeral";

export default {
    name: "Pricing",
    data() {
    return {
      lists: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      axios.defaults.headers.common["Authorization"] = "";
      axios
        .get("/v1/get-subcategory-list/")
        .then((response) => {
          console.log(response.data);
          this.lists = response.data.data;

          // this.manual_details = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    },
    makeImage(urlString) {
      return axios.defaults.baseURL + urlString;
    },
    formatNumber(numberString) {
      return numeral(numberString).format("0,0");
    },
  },
}
</script>

<style>

</style>