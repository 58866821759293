<template>
  <header class="header-section bg-color-3">
    <div class="header-bottom">
      <div class="container">
        <div class="header-wrapper">
          <div class="logo m-logo">
            <a href="/">
              <img
                class="dark"
                src="@/assets/frontend/images/logo.png"
                alt="logo"
              />
            </a>
          </div>
          <div class="menu-area">
            <ul class="menu menu--style1">
              <li>
                <a href="/">Home</a>
              </li>
             
              <li>
                <a href="/about-us">About</a>
                
              </li>

              <li>
                <a href="/pricing">Pricing</a>
                
              </li>

              
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
          <div class="header-action">
            <div class="menu-area">
              <div class="header-btn">
                <a href="/register" class="trk-btn trk-btn--border trk-btn--primary1"><span>Get Started</span></a>

                <a href="/login" class="trk-btn trk-btn--border trk-btn--primary1 head"><span>Login</span></a>
                
                
              </div>

              <!-- toggle icons -->
              <div class="header-bar d-lg-none header-bar--style1">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
 
};
</script>

<style scoped>
.m-logo img {
  width: 168px;
  height: auto;
}
.head-btn {
  background-color: #76a500;
}
</style>
