<template>
  <Shimmer class="row mb-3 page-loader" v-if="pageLoading" />

  <!-- Container Fluid-->
  <div v-else class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="network-logo">
              <img v-if="network_image" :src="network_image" alt="Data Logo" />
            </div>
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-warning" v-if="pending_flag">
              <span>{{ pending_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>

            <form @submit.prevent>
              <!-- {% csrf_token %} -->
              <div class="form-group">
                <label>Service Providers</label>
                <select
                  name="network"
                  id="network"
                  v-model="service_providers"
                  @change="handleNetworkChange"
                  class="form-control"
                  required
                >
                  <option value="">Select Providers</option>
                  <!-- {% for package in packages %} -->
                  <option v-for="pack in packages" :value="pack" :key="pack.id">
                    {{ pack.title }}
                  </option>
                  <!-- {% endfor %} -->
                </select>
              </div>
              <div class="form-group">
                <label>Data Plans</label>
                <select
                  name="data_plan"
                  id="data_plan"
                  v-model="data_plan"
                  class="form-control"
                  @change="HandleDataChange"
                  required
                >
                  <option>Select Plan</option>
                  <option v-for="plan in plans" :value="plan" :key="plan.key">
                    {{ plan.plan }} at ₦{{ plan.amount }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label> Amount</label>
                <input
                  name="amt"
                  type="number"
                  id="amt"
                  readonly
                  v-model="data_plan.amount"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label>Phone </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  v-model="phone"
                  @keydown="validateInput"
                  class="form-control"
                />
              </div>
              <div
                v-if="description"
                style="margin-bottom: 10px; color: #76a500"
              >
                {{ description }}
              </div> 
              <!-- <div
                v-if="bank_charged_text"
                style="margin-bottom: 10px; color: #0a8aaf"
              >
                {{ bank_charged_text }}
              </div> -->
              <div class="form-group">
                <button
                  type="submit"
                  v-if="isLoading == false"
                  @click="Process('wallet')"
                  class="btn btn-primary"
                >
                  Process
                </button>
                <button v-else class="btn btn-primary" disabled>
                  Processing...
                </button>
                
              </div>
              <router-link to="/dashboard/transactions" class="nav-link"
                ><i class="fas fa-fw fa-th"></i>
                <span>View Transactions</span></router-link
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---Container Fluid-->
</template>
  
  <script>
import axios from "axios";
import Shimmer from "vue3-loading-shimmer";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = "Bearer " + token;

export default {
  props: ["user", "profile"],
  name: "BuyData",
  components: {
    Shimmer,
  },
  data() {
    return {
      service_providers: {},
      data_plan: {},
      amt: "",
      bank_amt: "",
      phone: "",
      discount: 0,
      amount_charged_text: "",
      bank_charged_text: "",
      description: "",
      network_image: "",
      errors: [],
      success_flag: "",
      pending_flag: "",
      packages: {},
      plans: {},
      trans_reference: "",
      isLoading: false,
      isLoading2: false,
      rave_bank_charge: process.env.VUE_APP_FLW_BANK_CHARGE,
      rave_public_Key: process.env.VUE_APP_FLW_PUBLIC_KEY,
      isLoading: false,
      pageLoading: false,
    };
  },
  mounted() {
    this.getPackages();
  },

  methods: {
    validateInput(event) {
      if (
        event.shiftKey &&
        ((event.keyCode >= 48 && event.keyCode <= 57) ||
          (event.keyCode >= 186 && event.keyCode <= 222))
      ) {
        // Ensure that it is a number and stop the Special chars
        event.preventDefault();
      } else if (
        (event.shiftKey || event.ctrlKey) &&
        event.keyCode > 34 &&
        event.keyCode < 40
      ) {
        // let it happen, don't do anything
      } else {
        // Allow only backspace , delete, numbers
        if (
          event.keyCode == 9 ||
          event.keyCode == 46 ||
          event.keyCode == 8 ||
          event.keyCode == 39 ||
          event.keyCode == 37 ||
          (event.keyCode >= 48 && event.keyCode <= 57)
        ) {
          // let it happen, don't do anything
        } else {
          // Ensure that it is a number and stop the key press
          event.preventDefault();
        }
      }
    },
    getPackages() {
      axios
        .get("/v1/get-data-packages/")
        .then((response) => {
          // console.log(response.data.data);
          this.packages = response.data.data;
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
            this.errors.push(error.response.data.message);

            // console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    },

    handleNetworkChange() {
      this.amt = "";
      this.amount_charged_text = "";
      const subcategory_id = this.service_providers.id;
      if (this.service_providers != "") {
        axios
          .get("/v1/get-data-plans/" + subcategory_id + "/")
          .then((response) => {
            console.log(response.data.data[0].plans);
            this.plans = response.data.data[0].plans;
            // discount = response.data.amount;
            this.discount = response.data.data[0].amount;
            this.network_image =
              axios.defaults.baseURL + response.data.data[0].image;
            this.description = response.data.data[0].description;

            // console.log(this.discount)
            // this.amount_charged_text = this.getDiscount(this.amt,response.data.data.amount)
          });
      } else {
        this.network_image = "";
      }
      //   console.log(this.amount_charged_text);
    },

    HandleDataChange() {
      var bank_charge = (
        this.data_plan.amount +
        this.data_plan.amount * (this.rave_bank_charge / 100)
      ).toFixed(2);
      this.bank_charged_text =
        "Pay with bank charge ₦" + bank_charge.toString();
      this.bank_amt = bank_charge;
    },

    Process(action) {
      if (action == "wallet") {
        this.payFromWallet();
      } else {
        this.payFromBank();
      }
    },

    notify_success(text) {
      toast(text, {
        theme: "colored",
        type: "success",
        autoClose: 3000,
      });
    },

    notify_pending(text) {
      toast(text, {
        theme: "colored",
        type: "warning",
        autoClose: 3000,
      });
    },

    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },

    payFromWallet() {
      this.errors = [];
      this.amt = this.data_plan.amount;
      this.success_flag = "";
      this.pending_flag = "";

      if (this.phone == "") {
        this.errors.push("Phone number is required!");
        this.notify_failed("Phone number is required!");
      } else if (this.amt == "") {
        this.errors.push("Amount is required!");
        this.notify_failed("Amount is required!");
      } else if (this.amt < 10) {
        this.errors.push("Amount can not be less than ₦10");
        this.notify_failed("Amount can not be less than ₦10");
      } else if (this.service_providers == []) {
        this.errors.push("Select a provider from the list");
        this.notify_failed("Select a provider from the list");
      } else {
        const formData = {
          size: this.data_plan.size,
          title: this.data_plan.plan,
          subcategory_id: this.service_providers.id,
          phone_number: this.phone,
          amount: this.amt,
          variation_amount: this.data_plan.variation_amount,
          service_id: this.data_plan.service_id,
          variation_code: this.data_plan.variation_code,
        };
        console.log(formData);
        // "size": size,
        //     "title": plan_name,
        //     "subcategory_id": subcategory_id,

        this.isLoading = true;

        axios
          .post("/v1/purchase-data/", formData)
          .then((response) => {
            console.log(response);
            if (response.status == 200) {
              this.success_flag = response.data.message;
              this.notify_success(response.data.message);
            } else if (response.status == 201) {
              this.pending_flag = response.data.message;
              this.notify_pending(response.data.message);
            }

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },

    payFromBank() {
      this.errors = [];
      this.amt = this.data_plan.amount;
      this.success_flag = "";
      this.pending_flag = "";
      // console.log(this.amt);

      if (this.phone == "") {
        this.errors.push("Phone number is required!");
        this.notify_failed("Phone number is required!");
      } else if (this.amt == "") {
        this.errors.push("Amount is required!");
        this.notify_failed("Phone number is required!");
      } else if (this.amt < 10) {
        this.errors.push("Amount can not be less than ₦10");
        this.notify_failed("Amount can not be less than ₦10");
      } else if (this.service_providers == []) {
        this.errors.push("Select a provider from the list");
        this.notify_failed("Select a provider from the list");
      } else {
        const formData = {
          size: this.data_plan.size,
          title: this.data_plan.plan,
          subcategory_id: this.service_providers.id,
          phone_number: this.phone,
          amount: this.amt,
          variation_amount: this.data_plan.variation_amount,
          service_id: this.data_plan.service_id,
          variation_code: this.data_plan.variation_code,
        };
        console.log(formData);
        // "size": size,
        //     "title": plan_name,
        //     "subcategory_id": subcategory_id,

        this.isLoading2 = true;
        axios
          .post("/v1/initiate-order-data/", formData)
          .then((response) => {
            // console.log(response.data);
            this.trans_reference = response.data.data.trans_ref;
            // console.log(this.trans_reference);
            // this.isLoading = false;

            // Make Payment
            this.makePayment();
          })
          .catch((error) => {
            this.isLoading2 = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message);
              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
    makePayment() {
      FlutterwaveCheckout({
        public_key: 'FLWPUBK-269b4e32d162e2a34d6c8d5a9dcd7e70-X',
        tx_ref: this.trans_reference,
        amount: this.bank_amt,
        currency: "NGN",
        payment_options: "banktransfer,card, account, ussd",
        // redirect_url: "https://glaciers.titanic.com/handle-flutterwave-payment",

        customer: {
          email: this.user.email,
          phone_number: this.user.profile.phone_no,
          name: this.user.first_name + " " + this.user.last_name,
        },
        customizations: {
          title: "Topupwave",
          description: "Buy Data",
          //logo: "https://www.logolynx.com/images/logolynx/22/2239ca38f5505fbfce7e55bbc0604386.jpeg",
        },
        callback: (payment) => {
          // Send AJAX verification request to backend
          this.loading = false;

          // console.log(payment);
          if (payment.status == "completed") {
            this.success_flag = "Transaction will be processed shortly";
            this.notify_success("Transaction will be processed shortly");
          }

          // console.log(verifyTransactionOnBackend(payment.id));
        },

        onclose: (incomplete) => {
          // console.log(incomplete)
          // this.verifyTransaction(incomplete);

          // this.verifyTransaction(incomplete);

          if (incomplete === false) {
            this.success_flag = "Transaction will be processed shortly";
            this.notify_success("Transaction will be processed shortly");
            this.isLoading2 = false;
          } else {
            this.isLoading2 = false;
            this.errors.push("Transaction failed!");
            this.notify_failed("Transaction failed!");
          }
        },
      });

      // console.log(t);
    },
  },
};
</script>
  
  <style scoped>
.network-logo {
  text-align: center;
  margin-bottom: 20px;
}
.network-logo img {
  width: 100px;
}
.network-error {
  color: red;
}
.notification {
  margin: 10px 0 10px;
}
.card-body {
  padding: 30px;
}
label {
  font-weight: bold;
}
input {
  margin-bottom: 20px;
}
#amt {
  pointer-events: none;
}
a:link {
  text-decoration: none;
}
.btn-primary {
  background-color: #76a500;
  border-color: #76a500;
}

.nav-link {
  color: #310052;
}

.page-loader {
  height: 50%;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
}
</style>