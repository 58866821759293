<template>
    <div>
           <!-- ================> Page header start here <================== -->
    <section class="page-header bg-color-1"></section>
    <!-- ================> Page header end here <================== -->
      <Pricing/>
    </div>
 
</template>

<script>
import Pricing from '@/components/frontend/Pricing.vue'

export default {
    props: ["pages"],
    name: "PricingView",
    components: {
        Pricing,
    }
}
</script>

<style>

</style>