<template>
    <div>
        <!-- ================> Page header start here <================== -->
        <section class="page-header bg-color-1">
      
        </section>
        <!-- ================> Page header end here <================== -->
    <!-- ===============>> team details section start here <<================= -->
 <div class="team team--details padding-top padding-bottom bg-color-3">
    <div class="container">
      <div class="team__wrapper">
        <div class="row g-5 align-items-center">
          <div class="col-md-12">
            <div class="team__content">
              <h3>Privacy Policy</h3>
              <p v-html="pages.privacy"></p>

         
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ===============>> team details section end here <<================= -->
 
</div>
</template>

<script>
export default {
    props: ['pages'],
    name: "PrivacyView"
}
</script>

<style>
.page-header {
    height: 2px;
    padding-block: 0px;
  }

  h4 {
    font-size: 20px;
  }
</style>