<template>
  <Shimmer class="row mb-3 page-loader" v-if="pageLoading" />

  <!-- Container Fluid-->
  <div v-else class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="network-logo" v-if="service_providers.image">
              <img
                v-if="network_image"
                :src="network_image"
                alt="Electricity Logo"
              />
            </div>
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-warning" v-if="pending_flag">
              <span>{{ pending_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>

            <form @submit.prevent="submitForm">
              <!-- {% csrf_token %} -->
              <div class="form-group">
                <label>Service Providers</label>
                <select
                  name="service_providers"
                  v-model="service_providers"
                  @change="handleElecChange"
                  class="form-control"
                  required
                >
                  <option value="">Select Providers</option>
                  <!-- {% for package in packages %} -->
                  <option v-for="pack in packages" :value="pack" :key="pack.key">
                    {{ pack.title }}
                  </option>
                  <!-- {% endfor %} -->
                </select>
              </div>
              <div class="form-group">
                <label>Meter Type:</label>
                <select
                  name="mtype"
                  id="mtype"
                  v-model="meter_type"
                  class="form-control"
                  required
                >
                  <option value="prepaid">PREPAID</option>
                  <option value="postpaid">POSTPAID</option>
                </select>
              </div>

              <div v-if="service_providers.title" class="form-group">
                <label>Meter No</label>
                <input
                  type="text"
                  name="meter_no"
                  v-model="meter_no"
                  @keydown="validateInput"
                  required
                  class="form-control"
                />
                <button v-if="verify==false"
                  type="button"
                  @click="handleMeterNoChange"
                  class="btn btn-primary"
                >
                  Verify
                </button>
                <button v-else
                  type="button"
                  disabled
                  class="btn btn-primary"
                >
                  Verifying... Please wait
                </button>

                <div v-if="customer_show" class="show_det">
                  <p v-if="customer_details.Customer_Name">
                    <strong>Customer Name:</strong>
                    {{ customer_details.Customer_Name }}
                  </p>
                </div>
              </div>

              <div class="form-group">
                <label> Amount</label>
                <input
                  name="amt"
                  type="number"
                  id="amt"
                  v-model="amt"
                  @keyup="getDiscount"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label>Phone </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  v-model="phone"
                  @keydown="validateInput"
                  class="form-control"
                />
              </div>
              <div
              v-if="amount_charged_text"
              style="margin-bottom: 10px; color:#76a500;"
            >
              {{ amount_charged_text }}
            </div>
              <div v-if="validated" class="form-group">
                <button
                  type="submit"
                  v-if="isLoading == false"
                  class="btn btn-primary"
                >
                  Process
                </button>

                <button v-else class="btn btn-primary" disabled>
                  Processing...
                </button>
              </div>
              <div v-else class="form-group">
                <button class="btn btn-primary" disabled>Process</button>
              </div>

              <router-link to="/dashboard/transactions" class="nav-link"
        ><i class="fas fa-fw fa-th"></i> <span>View Transactions</span></router-link
      >
             
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---Container Fluid-->
</template>
      
      <script>
import axios from "axios";
import Shimmer from "vue3-loading-shimmer";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = "Bearer " + token;

export default {
  props: ['user','profile'],

  name: "Electricity",
  components: {
    Shimmer,
  },
  data() {
    return {
      service_providers: {},
      amt: "",
      phone: "",
      meter_no: "",
      service_id: "",
      meter_no: "",
      meter_type: "",
      customer_details: {},
      discount: 0,
      amount_charged_text: "",
      network_image: "",
      errors: [],
      success_flag: "",
      pending_flag: "",
      packages: {},
      plans: {},
      isLoading: false,
      isLoading2: false,
      validated: false,
      customer_show: false,
      pageLoading: false,
      verify: false,
    };
  },
  mounted() {
    this.getPackages();
  },

  methods: {
    validateInput(event){
      if(event.shiftKey && ((event.keyCode >=48 && event.keyCode <=57) 
             || (event.keyCode >=186 &&  event.keyCode <=222))){
        // Ensure that it is a number and stop the Special chars
         event.preventDefault();
     }
     else if ((event.shiftKey || event.ctrlKey) && (event.keyCode > 34 && event.keyCode < 40)){     
          // let it happen, don't do anything
     }      
     else{
        // Allow only backspace , delete, numbers               
        if (event.keyCode == 9 || event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 39 ||event.keyCode == 37 
                || (event.keyCode >=48 && event.keyCode <=57)) {
            // let it happen, don't do anything
        }
        else {
           // Ensure that it is a number and stop the key press
                event.preventDefault();                   
        }
     }
    },
    getPackages() {
      this.pageLoading = true
      this.errors = [];
      axios
        .get("/v1/get-electricity-packages/")
        .then((response) => {
          // console.log(response.data.data);
          this.packages = response.data.data;
          this.pageLoading = false
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
              this.errors.push(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          this.pageLoading = false
        });
    },

    validate(e) {
      const subcategory_id = this.service_providers.id;
      const service_idx = this.service_providers.title
        .split("-")[1]
        .toString()
        .trim();
      // console.log(service_idx)
      const service_idy = service_idx.toString().split(" ");
      // console.log(service_id1)

      const service_id1 = service_idy[0];
      const service_id2 = service_idy[1];
      // console.log(service_id2)
      const service_id = service_id1 + "-" + service_id2;
      const meter_no = this.meter_no;
      const meter_type = this.meter_type;

      this.customer_details = {};
      this.success_flag = "";
      this.pending_flag = "";
      this.errors = [];
      this.validated = false;
      this.verify = true;

      if (meter_no == "") {
        this.errors.push("Meter Number is required");
      } else if (meter_type == "") {
        this.errors.push("Select a meter type to continue");
      } else {
        this.errors = [];
        this.isLoading2 = true;
        this.amt = "";
        axios
          .get(
            "/v1/validate-electricity/" +
              subcategory_id +
              "/" +
              service_id +
              "/" +
              meter_no +
              "/" +
              meter_type +
              "/"
          )
          .then((response) => {
            console.log(response);
            var my_error = response.data.data.data.content.error;
            this.verify = false;
            if (my_error == undefined) {
              this.customer_details = response.data.data.data.content;
              this.customer_show = true;
              this.validated = true;
            } else {
              this.errors.push(my_error);
              this.customer_show = false;
            }

            this.isLoading2 = false;

            //   this.packages = response.data.data;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.verify = false;
          });
      }
    },

    handleElecChange() {
      this.errors = [];
      this.amt = "";
      this.amount_charged_text = "";
      this.customer_details = "";
      this.subscription_type = "";

      const service_type = this.service_providers.title;
      this.discount = this.service_providers.amount;
      this.network_image =
        axios.defaults.baseURL + this.service_providers.image;

      // this.network_image = "";
      this.validated = false;

      //   console.log(this.amount_charged_text);
    },

    handleMeterNoChange() {
      this.customer_details = {};
      this.validate();
    },

    getDiscount() {
      const myDiscount = this.service_providers.amount;

      var amount_charged = (this.amt - this.amt * (myDiscount / 100)).toFixed(2);

      this.amount_charged_text =
        "You will be charged ₦" + amount_charged.toString();

      // console.log(this.amount_charged_text);
    },
    notify_success(text) {
      toast(text, {
        theme: "colored",
        type: "success",
        autoClose: 3000,
      });
    },

    notify_pending(text) {
      toast(text, {
        theme: "colored",
        type: "warning",
        autoClose: 3000,
      });
    },

    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },

    submitForm(e) {
      this.errors = [];
      this.success_flag = "";
      this.pending_flag = "";

      if (this.phone == "") {
        this.errors.push("Phone number is required!");
        this.notify_failed("Phone number is required");
      } else if (this.amt == "") {
        this.errors.push("Amount is required!");
        this.notify_failed("Amount is required");
      } else if (this.amt < 500) {
        this.errors.push("Amount can not be less than ₦500");
        this.notify_failed("Amount can not be less than ₦500");
      } else if (this.service_providers == []) {
        this.errors.push("Select a provider from the list");
        this.notify_failed("Select a provider from the list");
      } else {
        const service_idx = this.service_providers.title
          .split("-")[1]
          .toString()
          .trim();
        // console.log(service_idx)
        const service_idy = service_idx.toString().split(" ");
        // console.log(service_id1)

        const service_id1 = service_idy[0];
        const service_id2 = service_idy[1];
        // console.log(service_id2)
        const service_id = service_id1 + "-" + service_id2;

        const formData = {
          subcategory_id: this.service_providers.id,
          phone: this.phone,
          billersCode: this.meter_no,
          variation_code: this.meter_type,
          service_id: service_id,
          amount: this.amt,
        };
        // console.log(formData);
        // "size": size,
        //     "title": plan_name,
        //     "subcategory_id": subcategory_id,

        this.isLoading = true;
        axios
          .post("/v1/purchase-electricity/", formData)
          .then((response) => {
            // console.log(response);
            if(response.status == 200){
              this.success_flag =
              response.data.message +
              ". Please check your transaction history for your TOKEN CODE.";
              this.notify_success(response.data.message +
              ". Please check your transaction history for your TOKEN CODE.");
            }else if(response.status == 201){
              this.pending_flag =
              response.data.message;
              this.notify_pending(response.data.message);
            }
            

            this.isLoading = false;
            this.meter_no = "";
            this.meter_type = "";
            this.customer_details = {};
            this.amt = "";
            this.phone = "";
            this.amount_charged_text = "";
            // this.service_providers = {};
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
      
      <style scoped>
.network-logo {
  text-align: center;
  margin-bottom: 20px;
}
.network-logo img {
  width: 100px;
}
.network-error {
  color: red;
}
.notification {
  margin: 10px 0 10px;
}
.card-body {
  padding: 30px;
}
label {
  font-weight: bold;
}
input {
  margin-bottom: 20px;
}
.row {
  margin-bottom: 50px;
}
.show_det {
  background-color: #cdcdcd;
  padding: 10px;
  border-radius: 5px;
  border-color: grey;
  margin: 10px 0 10px;
}

a:link {
  text-decoration: none;
}
.btn-primary{
  background-color: #76a500;
  border-color: #76a500;
}
.nav-link {
  color: #310052;
}
.page-loader{
  height: 50%;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
}
</style>