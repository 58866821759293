<template>
  <router-view></router-view>
</template>


<script>
import axios from "axios";
// import { loadScript } from "vue-plugin-load-script";
 
export default {
  name: "App",

  beforeCreate() {
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;

    axios.defaults.headers.common["Content-type"] = "application/json";


    // const user = this.$store.state.user
    // const profile = this.$store.state.profile

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  
 
};
</script>

