<template>
    <div>
       <!-- ================> Page header start here <================== -->
    <section class="page-header bg-color-1"></section>
    <!-- ================> Page header end here <================== -->
    <!-- ===============>> Contact section start here <<================= -->
    <div class="contact padding-top padding-bottom ">
        <div class="container">
          <div class="contact__wrapper text-center">
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="contact__info" data-aos="fade-right" data-aos-duration="1000">
                  <div class="contact__social">
                    <h3>let’s <span>get in touch</span>
                      with us</h3>
                   
                  </div>
                  <div class="contact__details">
                    <div class="contact__item" data-aos="fade-right" data-aos-duration="1000">
                      <div class="contact__item-inner">
                        <div class="contact__item-thumb">
                          <span><img src="@/assets/frontend/images/contact/1.png" alt="contact-icon"></span>
                        </div>
                        <div class="contact__item-content">
                          <p>
                            {{ pages.whatsapp_phone }}
                          </p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="contact__item" data-aos="fade-right" data-aos-duration="1100">
                      <div class="contact__item-inner">
                        <div class="contact__item-thumb">
                          <span><img src="@/assets/frontend/images/contact/2.png" alt="contact-icon"></span>
                        </div>
                        <div class="contact__item-content">
                          <p>
                            {{ pages.email }}
                          </p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="contact__item" data-aos="fade-right" data-aos-duration="1200">
                      <div class="contact__item-inner">
                        <div class="contact__item-thumb">
                          <span><img src="@/assets/frontend/images/contact/3.png" alt="contact-icon"></span>
                        </div>
                        <div class="contact__item-content">
                          <p>
                            {{ pages.address }}
                          </p>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <!-- ===============>> Contact section start here <<================= --> 
    </div>
    
</template>

<script>
export default {
    props: ['pages'],
    name: "ContactView",
}
</script>

<style scoped>
.contact__wrapper {
    display: flex;
    justify-content: center;
    padding:0;
    margin:0;
}
.contact__social h3 {
  padding: 0;
  margin: 0;
}

.contact__social,.contact__details {
    display: grid;
    justify-content: center;
    
}

.contact__social h3 {
    text-align:center;
    max-inline-size: inherit;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .contact__social,.contact__details {
    display: grid;
    justify-content: center;
}
}


</style>