<template>
    <Shimmer class="row mb-3 page-loader" v-if="pageLoading" />
  
    <div v-else id="page-top">
      <div id="wrapper">
        <Sidebar />
        <!-- Header -->
        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
            <Header :user="user" :profile="profile" />
            <router-view :user="user" :profile="profile"></router-view>
          </div>
          <footer class="sticky-footer bg-white">
            <!-- Footer -->
            <Footer />
          </footer>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Sidebar from "@/components/backend/layouts/Sidebar.vue";
  import Header from "@/components/backend/layouts/Header.vue";
  import Footer from "@/components/backend/layouts/Footer.vue";
  import axios from "axios";
  import Shimmer from "vue3-loading-shimmer";
  
  export default {
    name: "DashboardLayout",
    components: {
      Sidebar,
      Header,
      Footer,
      Shimmer,
    },
  
    data: function () {
      return {
        user: {},
        profile: {},
        pageLoading: false,
      };
    },
    mounted() {
      this.getUser();
    },
    watch: {
      getUser() {
        const token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        this.pageLoaded = true;
        axios
          .get("/v1/user/profile/")
          .then((response) => {
            this.user = response.data.data;
  
            this.profile = response.data.data.profile;
  
            this.pageLoaded = false;
          })
          .catch((error) => {
            if (error.response) {
              console.log(JSON.stringify(error.response.data));
              if (error.response.data.errors[0].code == "authentication_failed") {
                localStorage.removeItem("token");
                alert("Session Expired!, please login again");
                location.href = "/login";
              }
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.pageLoaded = false;
          });
      }
    },
    methods: {
      getUser() {
        const token = localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        this.pageLoaded = true;
        axios
          .get("/v1/user/profile/")
          .then((response) => {
            this.user = response.data.data;
  
            this.profile = response.data.data.profile;
  
            this.pageLoaded = false;
          })
          .catch((error) => {
            if (error.response) {
              console.log(JSON.stringify(error.response.data));
              if (error.response.data.errors[0].code == "authentication_failed") {
                localStorage.removeItem("token");
                alert("Session Expired!, please login again");
                location.href = "/login";
              }
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.pageLoaded = false;
          });
      },
    },
  };
  </script>
 <style scoped>
 .breadcrumb {
 background-color: transparent !important; 
 }

 
 </style>