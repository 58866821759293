<template>
    <div>
      <!-- ================> Page header start here <================== -->
      <section class="page-header bg-color-1">
        
      </section>
      <!-- ================> Page header end here <================== -->
  
      <!-- ===============>> account start here <<================= -->
      <section class="account padding-top padding-bottom">
        <div class="container">
          <div
            class="account__wrapper"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <div class="row g-4">
              <div class="col-lg-12">
                
                <div class="account__content account__content--style1">
                    <div class="notification" v-if="errors.length">
                        <p
                          v-for="error in errors"
                          v-bind:key="error"
                          class="alert alert-danger"
                        >
                          {{ error }}
                        </p>
                      </div>
                      <div
                        class="notification alert alert-success"
                        v-if="success_flag"
                      >
                        {{ success_flag }}
                      </div>
                  <!-- account tittle -->
                  <div class="account__header">
                    <h2>Reset your password</h2>
                    <p>Hey there! Forgot your password? No worries, just click "forgot password" and follow the steps to
                      recover it.</p>
                  </div>
                  <!-- account form -->
                  <form
                    @submit.prevent="submitForm"
                  >
                    <div class="row g-4">
                      <div class="col-12">
                        <div>
                          <label for="account-email" class="form-label"
                            >Email</label
                          >
                          <input
                            type="email"
                            class="form-control"
                            id="account-email"
                            v-model="email"
                            placeholder="Enter your email"
                            required
                          />
                        </div>
                      </div>
                     
                    </div>
  
                    <button v-if="isLoading==false"
                      type="submit"
                      class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4"
                    >
                      Forgot Password
                    </button>
                    <button v-else
                      class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4" disabled
                    >
                      Processing...
                    </button>
                  </form>
  
                  <div class="account__switch">
                    <p> <a href="/login"><i class="fa-solid fa-arrow-left-long"></i> Back to <span>Login</span></a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ===============>> account end here <<================= -->
  
    </div>
  </template>
  
  <script>
  
  
  import axios from "axios";
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  
  export default {
    name: "ForgotPasswordView",
  
    data() {
      return {
        email: "",
        success_flag: "",
        errors: [],
        isLoading: false,
      };
    },
    methods: {
      notify_failed(text) {
        toast(text, {
          theme: "colored",
          type: "error",
          autoClose: 3000,
        });
      },
      submitForm(e) {
        axios.defaults.headers.common["Authorization"] = "";
      this.errors = [];
      this.success_flag = "";
        if (this.email == "") {
          this.notify_failed("Email is required");
        } else{
        const formData = {
          email: this.email,
        };

        //   console.log(formData)
        this.isLoading = true;
        axios
          .post("/password_reset/", formData)
          .then((response) => {
            // console.log(response)
            this.isLoading = false;
            this.success_flag =
              "An Email has been sent to your email address, please follow the instructions to reset your password";
            this.notify_success("An Email has been sent to your email address, please follow the instructions to reset your password");
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data.errors) {
                this.errors.push(
                  `${error.response.data.errors[property]["detail"]}`
                );
                this.notify_failed(`${error.response.data.errors[property]["detail"]}`);
              }
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.isLoading = false;
          });
      }
      },
    },
  };
  </script>
  
  <style scoped>
  .breadcrumb-item,
  .breadcrumb-item a,
  .breadcrumb-item a:active,
  .breadcrumb-item:before {
    color: #ffffff;
  }
  .trk-btn--primary1 {
    background-color: #76a500;
  }
  .page-header {
    height: 2px;
    padding-block: 0px;
  }
  </style>