<template>
  <div>
    <!-- ================> Page header start here <================== -->
    <section class="page-header bg-color-1">
      
    </section>
    <!-- ================> Page header end here <================== -->

    <!-- ===============>> account start here <<================= -->
    <section class="account padding-top padding-bottom">
      <div class="container">
        <div
          class="account__wrapper"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <div class="row g-4">
            <div class="col-lg-12">
              <div class="account__content account__content--style1">
                <!-- account tittle -->
                <div class="account__header">
                  <h2>Welcome back!</h2>
                  <p>
                    Hey there! Ready to log in? Just enter your email address and
                    password below and you'll be back in action in no time.
                    Let's go!
                  </p>
                </div>

                <!-- account form -->
                <form
                  @submit.prevent="submitForm"
                >
                  <div class="row g-4">
                    <div class="col-12">
                      <div>
                        <label for="account-email" class="form-label"
                          >Email</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          id="account-email"
                          v-model="email"
                          placeholder="Enter your email"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-pass">
                        <label for="account-pass" class="form-label"
                          >Password</label
                        >
                        <input v-if="showPassword"
                          type="text"
                          class="form-control"
                          id="account-pass"
                          placeholder="Password"
                          v-model="password"
                          required
                        />
                        <input v-else
                          type="password"
                          class="form-control"
                          id="account-pass"
                          placeholder="Password"
                          v-model="password"
                          required
                        />

                        <button
                          type="button"
                          id="btnToggle"
                          class="form-pass__toggle"
                          
                        >
                          <!-- <i id="eyeIcon" class="fa fa-eye"></i> -->
                          <span @click="toggleShow" class="togglePassword"><i class="fa" :class="{ 'fa-eye-slash' :showPassword, 'fa-eye': !showPassword }"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="account__check">
                    <div class="account__check-remember">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value=""
                        id="terms-check"
                      />
                      <label for="terms-check" class="form-check-label">
                        Remember me
                      </label>
                    </div>
                    <div class="account__check-forgot">
                      <a href="/forgot-password">Forgot Password?</a>
                    </div>
                  </div>

                  <button v-if="isLoading==false"
                    type="submit"
                    class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4"
                  >
                    Sign in
                  </button>
                  <button v-else
                    class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4" disabled
                  >
                    Signing you in...
                  </button>
                </form>

                <div class="account__switch">
                  <p>
                    Don't have an account?
                    <a href="/register">Sign up</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ===============>> account end here <<================= -->

  </div>
</template>

<script>


import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "LoginView",

  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      showPassword: false,
    };
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show"
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword
    },
    passwordToggle(){
      this.password.type = "text";
    },
    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },
    submitForm(e) {
      if (this.email == "") {
        this.notify_failed("Email is required");
      } else if (this.password == "") {
        this.notify_failed("Password is required");
      }else{
        const formData = {
          email: this.email,
          password: this.password
        }
        this.isLoading = true;
      axios
        .post("/v1/login/", formData)
        .then((response) => {
          // console.log(response);
          const token = response.data.key;
          this.$store.commit("setToken", token);
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          localStorage.setItem("token", token);

          this.isLoading = false;
          //   localStorage.setItem("refresh_token", refresh_token);
          // this.$router.push("/dashboard");
          // this.$router.push("/dashboard");
          this.getUser();
        })
        .catch((error) => {
          console.log(error);
          
          if (error.response) {
              for (const property in error.response.data.errors) {
                
                this.notify_failed(`${error.response.data.errors[property]["detail"]}`);
              }
              // console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.isLoading = false
        });
      }
    },

    getUser() {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .get("/v1/user/profile/")
        .then((response) => {


            console.log(response);
          this.user_detail = response.data.data;

          this.profile_detail = response.data.data.profile;

          if(this.profile_detail.otp_verification == 1){
            location.href = "/dashboard";
          }else{
            location.href = "/otp";
          }
          
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            if(error.response.data.errors[0].code == "authentication_failed"){
              location.href="/login"
            }
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    
      },

  },
};
</script>

<style scoped>
.breadcrumb-item,
.breadcrumb-item a,
.breadcrumb-item a:active,
.breadcrumb-item:before {
  color: #ffffff;
}
.trk-btn--primary1 {
  background-color: #76a500;
}
.page-header {
  height: 2px;
  padding-block: 0px;
}
</style>