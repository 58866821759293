import { createRouter, createWebHistory } from 'vue-router'

import FrontendLayout from '@/components/frontend/layouts/FrontendLayout.vue'

import HomeView from '@/views/HomeView.vue'
import RegisterView from '@/views/frontend/RegisterView.vue'
import ForgotPasswordView from '@/views/frontend/ForgotPasswordView.vue'
import ResetPasswordView from '@/views/frontend/ResetPasswordView.vue'
import LoginView from '@/views/frontend/LoginView.vue'
import OtpView from '@/views/frontend/OtpView.vue'
import PersonalInfoView from '@/views/frontend/PersonalInfoView.vue'
import TermsView from '@/views/frontend/TermsView.vue'
import AboutView from '@/views/frontend/AboutView.vue'
import ContactView from '@/views/frontend/ContactView.vue'
import PricingView from '@/views/frontend/PricingView.vue'
import PrivacyView from '@/views/frontend/PrivacyView.vue'

import Profile from '@/views/backend/Profile'

import DashboardLayout from '@/components/backend/layouts/DashboardLayout'
import Dashboard from '@/views/backend/Dashboard'
import Airtime from '@/views/backend/Airtime'
import BuyData from '@/views/backend/BuyData'
import Cable from '@/views/backend/Cable'
import Electricity from '@/views/backend/Electricity'
import Exam from '@/views/backend/Exam'
import Transactions from '@/views/backend/Transactions'
import Sms from '@/views/backend/Sms'
import FundWallet from '@/views/backend/FundWallet'
import Receipt from '@/views/backend/Receipt'

import store from '../store'


const routes = [
  {
    path: "",
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'home',
        component:HomeView,
        meta:{
          title:"Home",
        },
      },
           
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta:{
        title:"Login",
      },
    },
    
    {
      path: '/otp',
      name: 'otp',
      component: OtpView
    },
    {
      path: '/personal-info',
      name: 'personal information',
      component: PersonalInfoView
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterView,
      meta:{
        title:"Register",
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot_password',
      component: ForgotPasswordView,
      meta:{
        title:"Forgot Password",
      },
    },
    {
      path: '/password_reset/confirm/',
      name: 'passwordReset',
      component: ResetPasswordView,
      meta:{
        title:"Reset Password",
      },
    },

    {
      path: '/terms-and-conditions',
      name: 'terms',
      component:TermsView,
      meta:{
        title:"Terms and Conditions",
      },
    },
    {
      path: '/about-us',
      name: 'aboutUs',
      component:AboutView,
      meta:{
        title:"About Us",
      },
    },
    {
      path: '/pricing',
      name: 'pricing',
      component:PricingView,
      meta:{
        title:"Pricing",
      },
    },
    {
      path: '/contact-us',
      name: 'contact',
      component:ContactView,
      meta:{
        title:"Contact",
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacy',
      component:PrivacyView,
      meta:{
        title:"Privacy",
      },
    },
    
    ]
  },

  {
    path: "",
    component: DashboardLayout,
    meta:{
      requireLogin:true,
    },
    props: true,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta:{
          requireLogin:true,
          title:"Dashboard",
          home_title: "Home",
        },
        props: true,
      },
      {
        path: '/dashboard/fund-wallet',
        name: 'Fund Wallet',
        component: FundWallet,
        meta:{
          requireLogin:true,
          title:"Fund Wallet",
          home_title: "Home",
        },
        props: true,
      },
      {
        path: '/dashboard/profile',
        name: 'Profile',
        component: Profile,
        meta:{
          requireLogin:true,
          title:"Profile Information",
          home_title: "Home",
        },
        props: true,
      },
      
      {
        path: '/dashboard/purchase-airtime',
        name: 'airtime',
        component: Airtime,
        meta:{
          requireLogin:true,
          title:"Airtime Purchase",
          home_title: "Home",
        },
        props: true,
      },
      {
        path: '/dashboard/purchase-data',
        name: 'data',
        component: BuyData,
        meta:{
          requireLogin:true,
          title:"Data Purchase",
          home_title: "Home",
        },
        props: true,
      },
      {
        path: '/dashboard/pay-for-tv',
        name: 'cable',
        component: Cable,
        meta:{
          requireLogin:true,
          title:"Pay for TV",
          home_title: "Home",
        },
      },
      {
        path: '/dashboard/pay-for-electricity',
        name: 'electricity',
        component: Electricity,
        meta:{
          requireLogin:true,
          title:"Pay for Electricity",
          home_title: "Home",
        },
      },
      {
        path: '/dashboard/purchase-exam-pins',
        name: 'exam',
        component: Exam,
        meta:{
          requireLogin:true,
          title:"Purchase Exam Pins",
          home_title: "Home",
        },
      },
      {
        path: '/dashboard/send-sms',
        name: 'sms',
        component: Sms,
        meta:{
          requireLogin:true,
          title:"Bulk SMS",
          home_title: "Home",
        },
      },
      {
        path: '/dashboard/transactions',
        name: 'transaction',
        component: Transactions,
        meta:{
          requireLogin:true,
          title:"Transactions",
          home_title: "Home",
        },
      },
      {
        path: "/dashboard/receipt/:trans_id",
        name: "receipt",
        component: Receipt,
        meta: {
            requiresLogin: true,
            title: "Receipt",
            home_title: "Home",
        },
    },
    ]
  }
 
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {    
  if(to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated){
    // console.log("redirect")
    next('/login')
  }else{
    window.document.title = to.meta && to.meta.title ? "Bills Pilot - "+ to.meta.title : 'Bills Pilot - Home';
    next()
  }
})

export default router
