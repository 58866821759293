<template>
    <!-- Header -->
  <div>
    <nav
      class="navbar navbar-expand navbar-light bg-navbar topbar mb-4 static-top"
    >
      <button
        id="sidebarToggleTop"
        @click="myFunctionClick()"
        class="btn btn-link rounded-circle mr-3"
      >
        <i class="fa fa-bars"></i>
      </button>
      <ul class="navbar-nav ml-auto">
        <div class="topbar-divider d-none d-sm-block"></div>
        <li class="nav-item dropdown no-arrow">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              class="img-profile rounded-circle"
              src="@/assets/backend/img/man.png"
              style="max-width: 60px"
            />
            <span class="ml-2 d-none d-lg-inline text-white small"
              >{{ user.first_name }} {{ user.last_name }}</span
            >
          </a>
          <div
            class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <router-link to="/dashboard/profile" class="dropdown-item">
              <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              Profile
            </router-link>
  
            <div class="dropdown-divider"></div>
            <a
                class="dropdown-item"
                href="javascript:void(0);"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </a>
  
            <!-- <a class="dropdown-item" @click="logout()" href="#">
              <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </a> -->
            
          </div>
        </li>
      </ul>
     
    </nav>
  
    <div
    class="modal fade"
    id="logoutModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabelLogout"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabelLogout">Hello Boss!</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to logout?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-info"
            data-dismiss="modal"
          >
            Cancel
          </button>
  
          <a
            class="btn btn-primary"
            href="javascript:void(0)"
            @click="logout()"
          >
            Logout</a
          >
        </div>
      </div>
    </div>
  </div>
  </div>
  </template>
  <script>
  import axios from "axios";
  
  export default {
    props: ['user','profile'],
    name: "Header",
    
    methods: {
      myFunctionClick: function () {
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        if ($(".sidebar").hasClass("toggled")) {
          $(".sidebar .collapse").collapse("hide");
        }
      },
  
      
      logout() {
        const token = localStorage.getItem("token");
        const formData = {
          token: token,
        };
        axios.post("v1/logout/", formData).then((response) => {
          // console.log(response);
  
          localStorage.removeItem("token");
          // this.$router.push("/");
          location.href='/login'
        });
      },
    },
  };
  </script>
  <style scoped>
  .dropdown-item:active{
    background-color: #0a8aaf;
  }
  .btn-primary {
    background-color: #76a500;
    border-color: #76a500;
  }
  .navbar {
    padding: 15px;
  }
  
  </style>