<template>
     <div>
            <!-- ================> Page header start here <================== -->
    <section class="page-header bg-color-1">
      
    </section>
    <!-- ================> Page header end here <================== -->
         <!-- ===============>> account start here <<================= -->

         <section class="account padding-top padding-bottom">
    <div class="container">
      <div class="account__wrapper" data-aos="fade-up" data-aos-duration="800">
        <div class="row g-4">
          <div class="col-lg-12">
            <div class="account__content account__content--style1">
                <div class="notification" v-if="errors.length">
                    <p
                      v-for="error in errors"
                      v-bind:key="error"
                      class="alert alert-danger"
                    >
                      {{ error }}
                    </p>
                  </div>
                  <div
                    class="notification alert alert-success"
                    v-if="success_flag"
                  >
                    {{ success_flag }}
                  </div>
              <!-- account tittle -->
              <div class="account__header">
                <h2>Reset your password</h2>
                <p>Hey there! Forgot your password? No worries, just click "forgot password" and follow the steps to
                  recover it</p>
              </div>


              <!-- account form -->
              <form action="#" class="account__form needs-validation" novalidate>
                <div class="row g-4">
                  <div class="col-12">
                    <div>
                      <label for="account-pass" class="form-label">New Password</label>
                      <input type="password" class="form-control" id="account-pass" v-model="new_password" placeholder="Enter a new password"
                        required>
                      <label for="account-pass" class="form-label">Confirm Password</label>
                      <input type="password" class="form-control" id="account-pass" v-model="confirm_password" placeholder="Enter a new password"
                        required>
                    </div>
                  </div>
                </div>

                <button type="submit" v-if="isLoading==false" class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4">Reset
                  password</button>
                <button v-else class="trk-btn trk-btn--border trk-btn--primary1 d-block mt-4" disabled>Resetting your password...</button>
              </form>


              <div class="account__switch">
                <p> <a href="/login"><i class="fa-solid fa-arrow-left-long"></i> Back to <span>Login</span></a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ===============>> account end here <<================= -->


     </div>
 


</template>
<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "ResetPasswordView",
  data() {
    return {
      new_password: "",
      confirm_new_password: "",
      password_token: "",
      success_flag: "",
      errors: [],
      isLoading: false,
    };
  },
  mounted() {
    // console.log(this.$route.query.token)
    this.password_token = this.$route.query.token;
  },
  methods: {
    notify_success(text) {
      toast(text, {
        theme: "colored",
        type: "success",
        autoClose: 3000,
      });
    },

    notify_pending(text) {
      toast(text, {
        theme: "colored",
        type: "warning",
        autoClose: 3000,
      });
    },

    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },
    submitForm(e) {
      axios.defaults.headers.common["Authorization"] = "";

      this.errors = [];
      this.success_flag = "";

      if (this.new_password == "") {
        this.errors.push("New Password is required");
        this.notify_failed("New Password is required");
      } else if (this.new_confirm_password == "") {
        this.errors.push("Confirm Password is required");
        this.notify_failed("Confirm Password is required");
      } else if (this.new_password != this.confirm_new_password) {
        this.errors.push("The two passwords doesn't match");
        this.notify_failed("The two passwords doesn't match");
      } else {
        const formData = {
          new_password: this.new_password,
          confirm_new_password: this.confirm_new_password,
          password_token: this.password_token,
        };

        this.isLoading = true;
        // console.log(formData)
        axios
          .post("/v1/reset_password/", formData)
          .then((response) => {
            console.log(response);
            this.success_flag = "Password reset done successfully";
            this.notify_success("Password reset done successfully");
            this.isLoading = false;
            location.href = "/login";
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              for (const property in error.response.data.data) {
                this.errors.push(`${error.response.data.data[property]}`);
                this.notify_failed(`${error.response.data.errors[property]["detail"]}`);
              }
              // console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.isLoading = false;
          });
      }
      // Profile details
    },
  },
};
</script>

<style>
.page-header {
    height: 2px;
    padding-block: 0px;
  }
</style>