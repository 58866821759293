<template>
  <Shimmer class="row mb-3 page-loader" v-if="pageLoading" />

  <div v-else class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>

            <form @submit.prevent="submitForm">
              <!-- {% csrf_token %} -->
              <div class="form-group">
                <label>Sender ID</label>
                <p><small style="color: #993300">Max. 11 characters</small></p>
                <input
                  name="smsfrom"
                  type="text"
                  v-model="sender_id"
                  maxlength="11"
                  class="form-control"
                  required
                />
              </div>

              <div class="form-group">
                <label>Phone Number (s)</label>
                <p>
                  <span style="color: #993300"
                    ><small
                      >One phone number per line or Separate multiple numbers
                      with comma. Formats 23480xxxx, 080xxxx etc. DO NOT INCLUDE
                      +</small
                    ></span
                  >
                </p>
                <textarea
                  class="form-control"
                  name="smsto"
                  rows="5"
                  v-model="phone"
                  placeholder="Phone number separated by commas"
                  required
                ></textarea>
              </div>

              <div class="form-group">
                <label>Message</label>
                <textarea
                  class="form-control"
                  name="smsmsg"
                  rows="5"
                  v-model="message"
                  placeholder="Enter your message"
                  @keyup="textCounter"
                  @keydown="textCounter"
                  required
                ></textarea>
                <div id="counter" style="color: #006699; font-size: 12px"></div>
              </div>

              <div
                v-if="price_per_page"
                style="font-weight: bold; margin-bottom: 10px"
              >
                {{ price_per_page }}
              </div>

              <div class="form-group">
                <label>SMS Rate</label>
                <input
                  type="number"
                  name="sms_rate"
                  id="sms_rate"
                  readonly
                  required
                  class="form-control"
                  v-model="this.sms_rate"
                />
              </div>
              <div class="form-group">
                <label>Total Amount</label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  readonly
                  required
                  class="form-control"
                  v-model="this.amount"
                />
              </div>

              <div class="form-group">
                <button
                  type="submit"
                  v-if="isLoading == false"
                  class="btn btn-primary"
                >
                  Process
                </button>

                <button v-else class="btn btn-primary" disabled>
                  Processing...
                </button>
              </div>

            
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Shimmer from "vue3-loading-shimmer";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  props: ['user','profile'],

  name: "Sms",
  components: {
    Shimmer,
  },
  data() {
    return {
      sender_id: "",
      phone: "",
      message: "",
      price_per_page: "",
      sms_rate: "",
      no_pages: 1,
      subcategory_id: "",
      amount:0,
      errors: [],
      success_flag: "",
      isLoading: false,
      pageLoading: false,

    };
  },
  mounted() {
    this.getPackages();
  },
  methods: {
    getPackages() {
      this.pageLoading = true
      this.errors = [];
      axios
        .get("/v1/get-sms-packages/")
        .then((response) => {
          // console.log(response.data.data[0]);
          this.sms_rate = response.data.data[0].amount;
          this.subcategory_id = response.data.data[0].id;
          this.pageLoading = false
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
            this.errors.push(`${error.response.data.errors[0].detail}`);

            console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
          this.pageLoading = false
        });
    },
    textCounter() {
      // Initialization
      const max_limit = 160;
      var sms_page = 0;
      var b1 = 0;
      var c1;

      var j = this.message.length - max_limit;

      if (this.message.length < max_limit) {
        sms_page = 1;
        b1 = this.message.length;
      } else {
        if (this.message.length >= 480) {
          var textm = this.message;
          this.message = textm.substring(0, 479);
        }
      }
      var h = this.message.length / max_limit;
      sms_page = Math.ceil(h);
      var g = sms_page - 1;
      b1 = this.message.length - g * max_limit;

      c1 = "Page: " + sms_page + " (" + b1 + " / " + max_limit + ") Characters";
      this.price_per_page = c1;
      this.no_pages = sms_page;

    //   console.log(this.amount);
    },
    notify_success(text) {
      toast(text, {
        theme: "colored",
        type: "success",
        autoClose: 3000,
      });
    },

    notify_pending(text) {
      toast(text, {
        theme: "colored",
        type: "warning",
        autoClose: 3000,
      });
    },

    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },
  
  submitForm(e) {
        this.errors = [];
        this.success_flag ="";

        const Phones = this.phone.split(",")
        const numOfPhones = Phones.length

        this.amount = (numOfPhones * this.no_pages * this.sms_rate).toFixed(2);
        console.log(this.amount)

        if (this.phone == "") {
          this.errors.push("Phone number is required!");
          this.notify_failed("Phone number is required!");
        } else if (this.sender_id == "") {
          this.errors.push("Sender ID is required!");
          this.notify_failed("Sender ID is required!");
        }else if (this.message == "") {
          this.errors.push("Message is required!");
          this.notify_failed("Message is required!");
        } else {
        
          const formData = {
            subcategory_id: this.subcategory_id,
            phone: this.phone,
            sender: this.sender_id,
            message: this.message,
            amount: this.amount,
          };
          // console.log(formData);
        
          this.isLoading = true;
          axios
            .post("/v1/send-sms/", formData)
            .then((response) => {
              console.log(response);
              this.success_flag = response.data.message;
              this.notify_success(response.data.message);
              this.isLoading = false;
              this.sender_id = "";
              this.message = "";
              this.phone = "";
            })
            .catch((error) => {
              this.isLoading = false;
              // console.log(error.response);
              if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify_failed(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            });
        }
      },

    }


};
</script>
<style scoped>
label {
  font-weight: bold;
}
#sms_rate{
    pointer-events: none;
}
a:link {
  text-decoration: none;
}
.btn-primary{
  background-color: #76a500;
  border-color: #76a500;
}

.nav-link {
  color: #310052;
}
.page-loader{
  height: 50%;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
}
</style>