<template>
    <div class="container my-auto py-2">
      <div class="copyright text-center my-auto">
        <p class="copyright">
          <a :href="facebook">Facebook</a> | <a :href="twitter">Twitter</a> |
          <a :href="instagram">Instagram</a>
        </p>
        <span
          >Copyright &copy; {{ copyRightDate }}
  
          <b><a href="#"> BillsPilot </a></b>
        </span>
      </div>
  
      <a class="scroll-to-top rounded" href="#page-top">
        <i class="fas fa-angle-up"></i>
      </a>
      {{ whatsapp(whatsapp_phone) }}
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: "Footer",
    data() {
      return {
        twitter: "",
        facebook: "",
        instagram: "",
        whatsapp_phone: "",
      };
    },
    computed: {
      copyRightDate() {
        return new Date().getFullYear();
      },
      websiteName() {
        return axios.defaults.baseURL;
      },
    },
    
    mounted(){
      this.getPages();
      
    },
    methods: {
      getPages() {
        axios
          .get("/v1/get-pages/")
          .then((response) => {
            // console.log(response.data.data);
            let social = response.data.data.social;
  
            let social_data = social.split("|");
  
            this.facebook = "https://facebook.com/" + social_data[0];
            this.instagram = "https://instagram.com/" + social_data[1];
            this.twitter = "https://twitter.com/" + social_data[2];
            this.whatsapp_phone = response.data.data.whatsapp_phone;

            // console.log(this.whatsapp_phone)
  
            // this.manual_details = response.data.data;
          })
          .catch((error) => {
            if (error.response) {
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      },
      whatsapp(phoneString) {
        var options = {
          whatsapp: phoneString, // WhatsApp number
          call_to_action: "Message us", // Call to action
          button_color: "#FF6550", // Color of button
          position: "left", // Position may be 'right' or 'left'
        };
        var proto = document.location.protocol,
          host = "getbutton.io",
          url = proto + "//static." + host;
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = url + "/widget-send-button/js/init.js";
        s.onload = function () {
          WhWidgetSendButton.init(host, proto, options);
        };
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      },
      
    },
  };
  </script>
  
  <style scoped>
  .copyright {
    margin-bottom: 20px;
  }
  .scroll-to-top {
    background-color: darkgray;
    color: darkgray;
  }
  </style>