<template>
  <div>
    <!-- ================> Page header start here <================== -->
    <section class="page-header bg-color-1"></section>
    <!-- ================> Page header end here <================== -->
  <!-- ===============>> About section start here <<================= -->
  <section class="about about--style1  bg-color-3">
    <div class="container">
      <div class="about__wrapper">
        <div class="row g-5 align-items-center">
          <div class="col-lg-6">
            <div class="about__thumb" data-aos="fade-right" data-aos-duration="800">
              <div class="about__thumb-inner">
                <div class="about__thumb-image floating-content">
                  <img class="dark" src="@/assets/frontend/images/about/1.png" alt="about-image">
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about__content" data-aos="fade-left" data-aos-duration="800">
                <div class="about__content-inner">
                    <div class="section-header section-header--max18 mb-1">
                      <h2>About Us</h2>
                    </div>
                    <p class="mb-0" v-html="pages.about_us"></p>
                    
                  </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </section>
  <!-- ===============>> About section start here <<================= -->
</div>
</template>

<script>
export default {
    props: ['pages'],
    name: "AboutView",

}
</script>

<style>

</style>