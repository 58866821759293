<template>
  <div>
     <Hero :pages="pages"/>
<!--   
  <div class="partner partner--gradient">
    <div class="container">
      <div class="partner__wrapper">
        <div class="partner__slider swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/1.png" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/2.png" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/3.png" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/4.png" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/5.jpg" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/6.png" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/7.jpg" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/8.jpg" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/9.jpg" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/10.png" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/11.png" alt="partner logo">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="partner__item">
                <div class="partner__item-inner">
                  <img src="@/assets/frontend/images/partner/light/12.png" alt="partner logo">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- ===============>> partner section end here <<================= -->

  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/frontend/layouts/Hero.vue'

export default {
  props: ['pages'],
  name: 'HomeView',
  components: {
    Hero,
  },
 
}
</script>
<style scoped>
.m-logo img {
  width: 20%;
}
.trk-btn.active.trk-btn--outline,.trk-btn--primary1 {
  background-color: #76a500;
}
.home-btn-outline:hover {
  background-color: #76a500;
}
.bg-color-2 {
  background-color: #ebeaf3;
}

</style>


