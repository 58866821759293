<template>
    <!-- Sidebar -->
    <ul class="navbar-nav sidebar sidebar-light accordion" id="accordionSidebar">
      <router-link
        to="/dashboard"
        class="sidebar-brand d-flex align-items-center justify-content-center"
      >
        <!-- <div class="sidebar-brand-icon">
          <img src="@/assets/frontend/images/logo.png">
        </div> -->
        <div class="sidebar-brand-text mx-3">BillsPilot</div>
      </router-link>
      <hr class="sidebar-divider my-0" />
      <li class="nav-item active">
        <router-link to="/dashboard" class="nav-link"
          ><i class="fas fa-fw fa-th"></i> <span>Dashboard</span></router-link
        >
      </li>
      <hr class="sidebar-divider" />
      <div class="sidebar-heading">Funding</div>
      <li class="nav-item">
        <router-link to="/dashboard/fund-wallet" class="nav-link"
          ><i class="fas fa-fw fa-credit-card"></i> <span>Fund Wallet</span></router-link
        >
      </li>
  
      <hr class="sidebar-divider" />
      <div class="sidebar-heading">Airtime &amp; Data</div>
  
      <li class="nav-item">
        <router-link to="/dashboard/purchase-airtime" class="nav-link"
          ><i class="fas fa-fw fa-mobile"></i> <span>Airtime</span></router-link
        >
      </li>
  
      <li class="nav-item">
        <router-link to="/dashboard/purchase-data" class="nav-link"
          ><i class="fas fa-fw fa-wifi"></i> <span>Data</span></router-link
        >
      </li>
      
      <hr class="sidebar-divider" />
      <div class="sidebar-heading">UTILITY BILL</div>
      <li class="nav-item">
        <router-link to="/dashboard/pay-for-tv" class="nav-link"
          ><i class="fas fa-fw fa-tv"></i> <span>Cable</span></router-link
        >
      </li>
  
      <li class="nav-item">
        <router-link to="/dashboard/pay-for-electricity" class="nav-link"
          ><i class="fas fa-fw fa-bolt"></i> <span>Electricity</span></router-link
        >
      </li>
  
      <!-- <hr class="sidebar-divider" />
      <div class="sidebar-heading">Exams</div>
      <li class="nav-item">
        <router-link to="/dashboard/purchase-exam-pins" class="nav-link"
          ><i class="fas fa-fw fa-graduation-cap"></i> <span>Exam Pins</span></router-link
        >
      </li>
   -->
      <hr class="sidebar-divider" />
      <div class="sidebar-heading">OTHERS</div>
      
      <!-- <li class="nav-item">
        <router-link to="/dashboard/withdraw" class="nav-link"
          ><i class="fas fa-fw fa-arrow-down"></i>
          <span>Withdraw</span></router-link
        >
      </li> -->
      <!-- <li class="nav-item">
        <router-link to="/dashboard/send-sms" class="nav-link"
          ><i class="fas fa-fw fa fa-envelope"></i> <span>SMS</span></router-link
        >
      </li> -->
      
      <li class="nav-item">
        <router-link to="/dashboard/transactions" class="nav-link"
          ><i class="fas fa-fw fa-th"></i> <span>Transactions</span></router-link
        >
      </li>
    
     
    </ul>
  </template>
  
  <script>
  export default {
    name: "Sidebar",
  };
  </script>

  <style scoped>
  .sidebar .sidebar-brand .sidebar-brand-icon img {
    max-height: 2.3rem !important;
    max-width: inherit;
}
  </style>