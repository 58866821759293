<template>
  <Shimmer class="row mb-3 page-loader" v-if="pageLoading" />

  <div v-else class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Transactions</li>
      </ol>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <!-- Simple Tables -->

          <div class="card shadow mb-6">
            <div class="card-body">
              <div class="table-responsive">
                <table
                  class="table table-bordered table-hover"
                  ref="myTransactions"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>Trans. Ref.</th>
                      <th>Plan</th>
                      <th>Phone No</th>
                      <th>Smart No.</th>
                      <th>Meter No.</th>
                      <th>Description</th>
                      <th>Description 2</th>
                      <th>Response</th>
                      <th>Amount</th>
                      <th>Exam Card</th>
                      <th>Token</th>
                      <th>Prev. Bal</th>
                      <th>Current Bal</th>
                      <th>Status</th>
                      <th>Date Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>Trans. Ref.</th>
                      <th>Plan</th>
                      <th>Phone No</th>
                      <th>Smart No.</th>
                      <th>Meter No.</th>
                      <th>Description</th>
                      <th>Description 2</th>
                      <th>Response</th>
                      <th>Amount</th>
                      <th>Exam Card</th>
                      <th>Token</th>
                      <th>Prev. Bal</th>
                      <th>Current Bal</th>
                      <th>Status</th>
                      <th>Date Created</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr
                      v-for="transaction in transactions"
                      :key="transaction.id"
                    >
                      <td>{{ transaction.transaction_ref }}</td>
                      <td>{{ transaction.plan }}</td>
                      <td>{{ transaction.phone_number }}</td>
                      <td>{{ transaction.smart_no }}</td>
                      <td>{{ transaction.meter_no }}</td>
                      <td>
                        <div v-if="transaction.description == null">
                          {{ transaction.description }}
                        </div>
                        <div v-else-if="transaction.description.length < 40">
                          {{ transaction.description }}
                        </div>
                        <div v-else>
                          <div v-if="showMoreDes == false">
                            {{
                              transaction.description.substring(0, 40) + "..."
                            }}
                          </div>
                          <div v-else>
                            {{ transaction.description }}
                          </div>
                          <button
                            @click="showMoreDes = !showMoreDes"
                            class="btn btn-success"
                          >
                            <i
                              v-if="showMoreDes == false"
                              class="fas fa-eye"
                            ></i>
                            <i v-else class="fas fa-eye-slash"></i>
                          </button>
                        </div>
                      </td>

                      <td>
                        <div v-if="transaction.description2 == null">
                          {{ transaction.description2 }}
                        </div>
                        <div v-else-if="transaction.description2.length < 40">
                          {{ transaction.description2 }}
                        </div>
                        <div v-else>
                          <div v-if="showMoreDesc == false">
                            {{
                              transaction.description2.substring(0, 40) + "..."
                            }}
                          </div>
                          <div v-else>
                            {{ transaction.description2 }}
                          </div>
                          <button
                            @click="showMoreDesc = !showMoreDes"
                            class="btn btn-success"
                          >
                            <i
                              v-if="showMoreDesc == false"
                              class="fas fa-eye"
                            ></i>
                            <i v-else class="fas fa-eye-slash"></i>
                          </button>
                        </div>
                      </td>
                      <td>
                        <div v-if="transaction.response == null">
                          {{ transaction.response }}
                        </div>
                        <div v-else-if="transaction.response.length < 40">
                          {{ transaction.response }}
                        </div>
                        <div v-else>
                          <div v-if="showMoreResp == false">
                            {{ transaction.response.substring(0, 40) + ".." }}
                          </div>
                          <div v-else>
                            {{ transaction.response }}
                          </div>
                          <button
                            @click="showMoreResp = !showMoreResp"
                            class="btn btn-success"
                          >
                            <i
                              v-if="showMoreResp == false"
                              class="fas fa-eye"
                            ></i>
                            <i v-else class="fas fa-eye-slash"></i>
                          </button>
                        </div>
                      </td>
                      <td>&#8358;{{ formatNumber(transaction.subtotal) }}</td>
                      <td>{{ transaction.exam_card }}</td>
                      <td>{{ transaction.token }}</td>

                      <td>
                        &#8358;{{ formatNumber(transaction.initial_balance) }}
                      </td>
                      <td>
                        &#8358;{{ formatNumber(transaction.new_balance) }}
                      </td>
                      <td v-if="transaction.status == 0">
                        <span class="badge badge-warning"> Pending </span>
                      </td>
                      <td v-else-if="transaction.status == 1">
                        <span class="badge badge-success"> Confirmed </span>
                      </td>
                      <td v-else-if="transaction.status == 2">
                        <span class="badge badge-danger"> Failed </span>
                      </td>
                      <td v-else-if="transaction.status == 3">
                        <span class="badge badge-danger"> Ignored </span>
                      </td>
                      <td v-else-if="transaction.status == 4">
                        <span class="badge badge-info"> Reversed </span>
                      </td>
                      <td>{{ formatDate(transaction.created_at) }}</td>
                      <td>
                        <router-link
                          v-if="transaction.status == '1'"
                          :to="{
                            name: 'receipt',
                            params: { trans_id: transaction.id},
                          }"
                          class="btn btn-primary"
                          >Receipt
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
      <!--Row-->

      <!---Container Fluid-->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import numeral from "numeral";
import Shimmer from "vue3-loading-shimmer";

export default {
  props: ['user','profile'],

  name: "Transactions",
  components: {
    Shimmer,
  },
  data() {
    return {
      transactions: [],
      showMoreDes: false,
      showMoreDesc: false,
      showMoreResp: false,
      pageLoading: false,
    };
  },

  mounted() {
    this.dt = $(this.$refs.myTransactions).DataTable({
      order: [[14, "desc"]], //or asc
      columnDefs: [{ targets: 14, type: "date" }],
    });
    // setTimeout(() => this.transactions = fakeData(50), 1000);
    this.getTransactions();
  },
  watch: {
    transactions(val) {
      this.dt.destroy();
      this.$nextTick(() => {
        this.dt = $(this.$refs.myTransactions).DataTable({
          order: [[14, "desc"]], //or asc
          columnDefs: [{ targets: 14, type: "date" }],
        });
      });
    },
  },

  methods: {
    getTransactions() {
      this.pageLoading = true
      axios
        .get("/v1/get-transactions/")
        .then((response) => {
          //   console.log(response.data.data);
          this.transactions = response.data.data;
          this.pageLoading = false
          // this.profile = response.data.data.profile
          // this.formatDate = moment(response.data.data.date_joined).format("Do MMM, YYYY")
          // this.user_level = getUserLevel(response.data.data.profile.user_level)
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
                  this.pageLoading = false

        });
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return moment(date).format("Do MMM, YYYY,  h:mm:ss a");
      // Then specify how you want your dates to be formatted
      // return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
    },
    formatNumber(numberString) {
      return numeral(numberString).format("0,0.00");
    },
  },
};
</script>
<style scoped>
a:link {
  text-decoration: none;
}
.btn-primary{
  background-color: #76a500;
  border-color: #76a500;
}
.btn-success{
  background-color: #76a500;
  border-color:#76a500;
}
.page-loader{
  height: 50%;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
}
</style>