<template>
  <Shimmer class="row mb-3 page-loader" v-if="pageLoading" />
  <!-- Container Fluid-->
  <div v-else class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path:'/dashboard' }">{{ this.$route.meta.home_title }} </router-link>
        </li>
        <li class="breadcrumb-item">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>

    <!-- <div
      class="modal fade"
      id="infoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabelTopup"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabelTopup">
              Attention
            </h5>
  
            <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          @click="modalClose"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
          </div>
          <div class="modal-body">As Directed by CBN (Central Bank of Nigeria), <br/> It is compulsory that you link up your means of identification before any virtual accounts for wallet funding, Kindly do this to avoid <b>funding</b> issues in future... <br/> To update means of identification <a class="btn btn-primary" href="/dashboard/profile"> click here </a></div>
        </div>
      </div>
    </div> -->

    <!-- {% if notification is not None %} -->
    <div role="alert" class="alert alert-success alert-dismissible">
      <button
        type="button"
        data-dismiss="alert"
        aria-label="Close"
        class="close"
      >
        <span aria-hidden="true">×</span>
      </button>
      <i class="fa fa-info"></i>
      {{ my_notification }}
    </div>
    <!-- {% endif %} -->
    <div class="row mb-3">
      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-12 col-md-6 mb-4 mx-auto">
        <div class="card h-100">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-uppercase mb-1">
                  Wallet Balance
                </div>
                
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  &#8358;{{ formatNumber(profile_detail.wallet_balance) }}
                </div>
                
              </div>
              <div class="col-auto">
                <router-link to="/dashboard/fund-wallet" class="btn btn-success">Fund Wallet</router-link>
              </div>
              
            </div>
          </div>
        </div>
        <div class="space"></div>
      </div>
    </div>
    <div class="row mb-3">
    
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="row align-items-center">
              
              <div class="col mr-2">
                <div class="col-auto d-flex justify-content-center">
                  
                  <router-link to="/dashboard/purchase-airtime" class="text-success"><i class="fas fa-mobile fa-2x text-success"></i></router-link>
                </div>
                <div class="space-10"></div>
                <div class="text-xl font-weight-bold mb-1 d-flex justify-content-center">
                 <router-link to="/dashboard/purchase-airtime" class="text-success">Purchase Airtime</router-link> 
                </div>

              </div>
              
              
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="row align-items-center">
              
              <div class="col mr-2">
                <div class="col-auto d-flex justify-content-center">
                  <router-link to="/dashboard/purchase-data" class="text-success"><i class="fas fa-wifi fa-2x text-success"></i></router-link>
                
              </div>
              <div class="space-10"></div>
                <div class="text-xl font-weight-bold mb-1 d-flex justify-content-center">
                 <router-link to="/dashboard/purchase-data" class="text-success">Purchase Data</router-link> 
                </div>
                
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="row align-items-center">
              
              <div class="col mr-2">
                <div class="col-auto d-flex justify-content-center">
                  <router-link to="/dashboard/pay-for-tv" class="text-success"><i class="fas fa-tv fa-2x text-success"></i></router-link> 
                  
                </div>
                <div class="space-10"></div>
                
                <div class="text-xl font-weight-bold mb-1 d-flex justify-content-center">
                 <router-link to="/dashboard/pay-for-tv" class="text-success">Pay for Cable</router-link> 
                </div>
                
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="row align-items-center">
              
              <div class="col mr-2">
                <div class="col-auto d-flex justify-content-center">
                
                <router-link to="/dashboard/pay-for-electricity" class="text-success"><i class="fas fa-bolt fa-2x text-success"></i></router-link> 
              </div>
              <div class="space-10"></div>
                <div class="text-xl font-weight-bold mb-1 d-flex justify-content-center">
                 <router-link to="/dashboard/pay-for-electricity" class="text-success">Pay for Electricity</router-link> 
                </div>
                
              </div>
             
              
            </div>
          </div>
        </div>
      </div>
<!-- 
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="row align-items-center">
             
              <div class="col mr-2">
                <div class="col-auto d-flex justify-content-center">
                
                <router-link to="/dashboard/purchase-exam-pins" class="text-success"><i class="fas fa-graduation-cap fa-2x text-success"></i></router-link> 
              </div>
              <div class="space-10"></div>
                <div class="text-xl font-weight-bold mb-1 d-flex justify-content-center">
                 <router-link to="/dashboard/purchase-exam-pins" class="text-success">Exam Pins</router-link> 
                </div>
                
              </div>
              
              
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="row align-items-center">
             
              <div class="col mr-2">
                <div class="col-auto d-flex justify-content-center">
                
                <router-link to="/dashboard/send-sms" class="text-success"><i class="fas fa-envelope fa-2x text-success"></i></router-link>
              </div>
              <div class="space-10"></div>
                <div class="text-xl font-weight-bold mb-1 d-flex justify-content-center">
                 <router-link to="/dashboard/send-sms" class="text-success">Send SMS</router-link> 
                </div>
                
              </div>
             
              
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-xl-3 col-md-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto">
                <i class="fas fa-arrow-down fa-2x text-success"></i>
              </div>
              <div class="col mr-2">
                <div class="text-xl font-weight-bold mb-1">
                 <router-link to="/dashboard/withdraw" class="text-success">Withdraw</router-link> 
                </div>
                
              </div>
              
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="row align-items-center">
              
              <div class="col mr-2">
                <div class="col-auto d-flex justify-content-center">
                
                <router-link to="/dashboard/transactions" class="text-success"><i class="fas fa-th fa-2x text-success"></i></router-link> 
              </div>
              <div class="space-10"></div>
                <div class="text-xl font-weight-bold mb-1 d-flex justify-content-center">
                 <router-link to="/dashboard/transactions" class="text-success">Transactions</router-link> 
                </div>
                
              </div>
             
              
            </div>
          </div>
        </div>
      </div>



    </div>
    <!--Row-->
  </div>
  <!---Container Fluid-->
</template>

<script>
import axios from "axios";
import moment from "moment";
import numeral from "numeral";
import Shimmer from "vue3-loading-shimmer";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = "Bearer " + token;

export default {
  name: "Dashboard",
  components: {
    Shimmer,
  },
  data() {
    return {
      user_detail: {},
      profile_detail: {},
      transactions: [],
      my_notification:"",
      isLoading: false,
      pageLoading: false,
    };
  },
  
  mounted() {
    this.getUser();
    this.getSettings();
  },
  methods: {
    getUser() {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .get("/v1/user/profile/")
        .then((response) => {
            console.log(response);
          this.user_detail = response.data.data;

          this.profile_detail = response.data.data.profile;

          // if(this.profile.bvn == null && this.profile.nin == null){
          //   $(document).ready(function(){
          //   $('#infoModal').modal('show');
          //  })
          // }
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            if(error.response.data.errors[0].code == "authentication_failed"){
              location.href="/login"
            }
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    
      },

    notify_success(text) {
      toast(text, {
        theme: "colored",
        type: "success",
        position: "top-center",
        autoClose: 5000,
        dangerouslyHTMLString: true
      });
    },

    notify_failed(text) {
      toast(text, {
        theme: "colored",
        type: "error",
        autoClose: 3000,
      });
    },


    getSettings() {
      this.pageLoading = true
      axios
        .get("/v1/get-settings/")
        .then((response) => {
          console.log(response.data.data);
          const notification = response.data.data.notification;
          this.my_notification = notification.replace(/<\/?[^>]+(>|$)/g,"");
          this.notify_success(notification.replace(/<\/?[^>]+(>|$)/g,""));

          this.pageLoading = false
         
          // this.manual_details = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
              this.notify_failed(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          this.pageLoading = false
        });
    },

    translateLevel(levelString) {
      // console.log(this.user_level)
      switch (levelString) {
        case 0:
          return "Normal";
          break;
        case 1:
          return "Agent";
          break;
        case 2:
          return "Reseller";
          break;
        case 3:
          return "API";
          break;

        default:
          return "Normal";
          break;
      }
    },
    formatDate(dateString) {
    const date = new Date(dateString);
     return moment(date).format("Do MMM, YYYY");
             
    },

    formatNumber(numberString) {
      return numeral(numberString).format("0,0.00");
    },

    modalClose(){
        $(document).ready(function(){
            $('#infoModal').modal('hide')
        })

    }
    
  },
};
</script>

<style lang="scss" scoped>
.space {
  margin:50px;
}
.space-10 {
  padding-top: 10px;
}
a:link{
  text-decoration: none;
}
.alert-success,.btn-success{
  background-color: #310052;
}
.btn-success{
  background-color: #76a500;
  border-color:#76a500;
}
.text-success{
  color: #310052 !important;
}
a.text-success:hover,a.text-success:focus{
  color: #310052 !important;
}
.btn-primary{
  background-color: #76a500;
  border-color: #76a500;
}
.page-loader{
  height: 50%;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
}
</style>

